import axios from 'axios';
import { log, showError } from "./logger";


export const fetchUserProfileColors = async (address: string): Promise<{ primary: string, secondary: string }> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND}/basecolors/getProfileColors/${address}`);
    return response.data;
  } catch (error) {
    showError("Error fetching user profile colors:", error);
    throw error;
  }
};

export const storeUserProfileColors = async (address: string, primary: string, secondary: string): Promise<void> => {
  log("Storing user profile colors with the following parameters:");
  log("Address:", address);
  log("Primary Color:", primary);
  log("Secondary Color:", secondary);

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND}/basecolors/storeProfileColors/${address}?primary=${encodeURIComponent(primary)}&secondary=${encodeURIComponent(secondary)}`);
    log("Store response:", response.data); // Add logging to verify the response
  } catch (error) {
    showError("Error storing user profile colors:", error);
    throw error;
  }
};

export function getContrastColor(hexColor: string): string {
  // Remove the hash if it's there
  hexColor = hexColor.replace('#', '');

  // Convert to RGB
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black for light colors and white for dark colors
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}