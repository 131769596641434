import React, { useMemo, useState } from 'react';
import { usePrivy } from "@privy-io/react-auth";
import { useTheme } from '../../context/ThemeContext';
import { useUser } from '../../context/UserContext';
import { useGridView } from '../../context/GridViewContext'; // Add this import
import SidebarHeader from './SidebarHeader';
import SidebarNavLinks from './SidebarNavLinks';
import UserDropdown from './UserDropdown';
import WalletConnect from '../WalletConnect';
import DailyThemeInfo from '../moshicam/DailyThemeInfo';
import { FaBars, FaTimes } from "react-icons/fa";
import TopMenu from './TopMenu'; // Ensure this import is present
import GridModeOn from '../../icons/GridModeOn.svg';
import GridModeOff from '../../icons/GridModeOff.svg';
import SidebarHeaderMobile from './SidebarHeaderMobile'; // Add this import
import GridMode from './GridMode'; // Add this import

interface SidebarProps {
  isMobile: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = React.memo(({ isMobile, isOpen, onClose }) => {
  const { authenticated } = usePrivy();
  const { primaryColor, textColor } = useTheme(); // Add textColor here
  const { connectedEnsName, connectedWalletAddress, logout } = useUser();
  const { isGridViewEnabled, toggleGridView } = useGridView(); // This should now work
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sidebarStyle = useMemo(() => ({
    backgroundColor: primaryColor,
    color: textColor, // Add this line to set the text color
  }), [primaryColor, textColor]); // Add textColor to dependencies

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    if (isMobile) {
      onClose();
    }
  };

  return (
    <>
      {isMobile && (
        <SidebarHeaderMobile
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          isGridViewEnabled={isGridViewEnabled}
          toggleGridView={toggleGridView}
          onClose={onClose}
        />
      )}
      {isMenuOpen && isMobile && (
        <TopMenu
          isMobile={isMobile}
          text="The Grid"
          onLinkClick={handleLinkClick}
          textColor={textColor}
          isOpen={isMenuOpen}
        />
      )}
      <div
        className={`fixed inset-y-0 left-0 z-50 w-64 bg-white shadow-lg transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out ${isMobile ? 'hidden' : ''}`}
        style={sidebarStyle}
      >
        <div className="flex flex-col h-full p-2">
          <SidebarHeader isMobile={isMobile} />
          <div className="flex items-center justify-between mb-4">
            <SidebarNavLinks onLinkClick={isMobile ? onClose : undefined} />
            {!isMobile && <GridMode />}
          </div>
          <DailyThemeInfo textColor={textColor} />
          {authenticated ? (
            <UserDropdown isMobile={isMobile} textColor={textColor} />
          ) : (
            <div className="flex justify-center mt-4">
              <WalletConnect textColor={textColor} />
            </div>
          )}
          <div className="flex-grow"></div>
          {/* You can add additional content here if needed */}
        </div>
      </div>
    </>
  );
});

export default Sidebar;