import React, { useState, useCallback, useEffect, useRef } from 'react';
import { usePrivy } from "@privy-io/react-auth";
import { useTheme } from '../../context/ThemeContext';
import { useUser } from '../../context/UserContext';
import ColorPicker from './ColorPicker';
import farcasterLogo from '../../icons/farcaster-transparent-purple.png';
import lensLogo from '../../icons/lens-logo.svg';
import GradientButton from './GradientButton';
import ensLogo from '../../icons/ens-logo.svg';

interface UserDropdownProps {
  isMobile: boolean;
  textColor: string; // Add this line
}

const UserDropdown: React.FC<UserDropdownProps> = React.memo(({ isMobile, textColor }) => {
  const { connectedEnsName, connectedWalletAddress, farcasterUsernames, lensUsernames, logout } = useUser();
  const { primaryColor, secondaryColor, setPrimaryColor, setSecondaryColor, selectedColors, setSelectedColors } = useTheme();
  const [isOpen, setIsOpen] = useState(!isMobile); // Start open on desktop
  const [isHovering, setIsHovering] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startCloseTimer = useCallback(() => {
    if (!isMobile && !isHovering) {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setIsOpen(false);
      }, 5000);
    }
  }, [isMobile, isHovering]);

  const clearCloseTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (!isMobile && isOpen) {
      startCloseTimer();
    }
    return clearCloseTimer;
  }, [isMobile, isOpen, startCloseTimer, clearCloseTimer]);

  const toggleDropdown = () => {
    if (!isMobile) {
      setIsOpen(prev => !prev);
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovering(true);
      clearCloseTimer();
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovering(false);
      startCloseTimer();
    }
  };

  const handleColorSelect = useCallback((colors: string[]) => {
    setSelectedColors(colors);
    setPrimaryColor(colors[0]);
    setSecondaryColor(colors[1]);
  }, [setPrimaryColor, setSecondaryColor, setSelectedColors]);

  return (
    <div 
      className={`relative mt-4 bg-opacity-10`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isMobile && (
        <button
          onClick={toggleDropdown}
          className="flex items-center space-x-2 w-full justify-between p-2 bg-white bg-opacity-10 font-['Jersey_15'] text-xl"
        >
          <span className="uppercase">
            {connectedEnsName ? (
              <span className="flex items-center space-x-2 uppercase">
                <img src={ensLogo} alt="ENS" className="w-4 h-4" />
                &nbsp;&nbsp;{connectedEnsName.toUpperCase()}
              </span>
            ) : farcasterUsernames.length > 0 ? (
              <span className="flex items-center space-x-2 uppercase">
                <img src={farcasterLogo} alt="Farcaster" className="w-4 h-4" />
                &nbsp;{farcasterUsernames[0].toUpperCase()}
              </span>
            ) : lensUsernames.length > 0 ? (
              <span className="flex items-center space-x-2 uppercase">
                <img src={lensLogo} alt="Lens" className="w-4 h-4" />
                &nbsp;{lensUsernames[0].toUpperCase()}
              </span>
            ) : (
              <span className="uppercase">{connectedWalletAddress.slice(0, 4)}.....{connectedWalletAddress.slice(-4)}</span>
            )}
          </span>
          <span className={`transform ${isOpen ? 'rotate-180' : ''}`}>▼</span>
        </button>
      )}
      
      {(isOpen || isMobile) && (
        <div className={`w-full bg-white shadow-lg py-0 z-50 ${isMobile ? 'flex flex-col justify-between' : ''} bg-opacity-10`}>
          <div className={`px-2 py-2 ${isMobile ? 'flex-1 overflow-y-auto' : ''}`}>
            <div className={`${isMobile ? 'flex flex-row h-full' : ''}`}>
              <div className="w-full font-['Jersey_15'] text-xl">
                {connectedEnsName && (
                  <div className="flex items-center space-x-1">
                    <img src={ensLogo} alt="ENS" className="w-4 h-4" />
                    <span>&nbsp;{connectedEnsName}</span>
                  </div>
                )}
                {farcasterUsernames.length > 0 && (
                  <div>
                    {farcasterUsernames.map((username, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <a href={`https://warpcast.com/${username}`} target="_blank" rel="noopener noreferrer">
                          <img src={farcasterLogo} alt="Farcaster" className="w-4 h-4" />
                        </a>
                        <span>{username}</span>
                      </div>
                    ))}
                  </div>
                )}
                {lensUsernames.length > 0 && (
                  <div>
                    {lensUsernames.map((username, index) => (
                      <div key={index} className="flex items-center space-x-2">
                        <a href={`https://hey.xyz/u/${username}`} target="_blank" rel="noopener noreferrer">
                          <img src={lensLogo} alt="Lens" className="w-4 h-4" />
                        </a>
                        <span>{username}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {isMobile && (
                <div className="w-full mt-[-10px] flex justify-end items-start">
                  <ColorPicker
                    onColorSelect={handleColorSelect}
                    selectedColors={selectedColors}
                  />
                </div>
              )}
            </div>
            {!isMobile && (
              <div className="w-full mt-4">
                <ColorPicker
                  onColorSelect={handleColorSelect}
                  selectedColors={selectedColors}
                />
              </div>
            )}
            <div className={`${isMobile ? 'mt-0' : 'mt-4'}`}>
              <GradientButton
                onClick={logout}
                className={`block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-opacity-80 flex items-center justify-between h-10 font-['Jersey_15'] text-xl`}
                style={{
                  '--hover-bg-color': secondaryColor,
                }}
              >
                <span>&nbsp;&nbsp;&nbsp;Log Out&nbsp;</span>
                <span className="truncate">
                  &nbsp;{connectedWalletAddress.slice(0, 4)}.....{connectedWalletAddress.slice(-4)}&nbsp;&nbsp;&nbsp;
                </span>
              </GradientButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default UserDropdown;