// src/Providers.tsx

import React, { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { PrivyProvider } from '@privy-io/react-auth';
import { ThemeProvider } from '../context/ThemeContext';
import { GridViewProvider } from '../context/GridViewContext';
import { ColorProvider } from '../context/ColorContext';
import { MobileProvider } from '../context/MobileContext';
import { UserProvider } from '../context/UserContext';
import { QueryClient, QueryClientProvider } from 'react-query'; // Add this import

interface ProvidersProps {
  children: ReactNode;
}

const queryClient = new QueryClient(); // Add this line

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}> {/* Add this wrapper */}
      <Router>
        <PrivyProvider
          appId={process.env.REACT_APP_PRIVY_APP_ID || ''}
          config={{
            loginMethods: ['wallet'],
            appearance: {
              landingHeader: 'The Grid',
              loginMessage: 'Sign up by choosing Coinbase Wallet',
              theme: 'dark',
              accentColor: '#676FFF',
              logo: 'https://www.thegrid.pics/TheGridLogo.svg', // Use the imported SVG
              walletList: ['coinbase_wallet', 'rainbow', 'metamask', 'wallet_connect'],
            },
          }}
        >
          <UserProvider>
            <ThemeProvider>
              <GridViewProvider>
                <ColorProvider>
                  <MobileProvider>
                    {children}
                  </MobileProvider>
                </ColorProvider>
              </GridViewProvider>
            </ThemeProvider>
          </UserProvider>
        </PrivyProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default Providers;
