import React, { useState } from "react";
import leftArrow from "../../icons/leftArrow.svg";
import rightArrow from "../../icons/rightArrow.svg";
import { ImageModalProps } from "../../types/types";

const ImageModal: React.FC<ImageModalProps> = ({
  images,
  currentIndex,
  onClose,
  onNavigateNext,
  onNavigatePrevious,
}) => {
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const currentImage = images[currentIndex];
  if (!currentImage) return null;

  const hasMultipleImages = images.filter((img) => img !== null).length > 1;

  return (
    <div
      className="fixed inset-0 bg-none bg-opacity-0 flex items-center justify-center z-50 cursor-pointer"
      onClick={onClose}
    >
      <div
        className="relative max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl mx-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={currentImage.image}
          alt="Expanded view"
          className="max-w-[95%] max-h-[95vh] mx-auto object-contain cursor-pointer"
        />

        {hasMultipleImages && (
          <>
            <div
              className="absolute left-0 top-0 bottom-0 w-1/3 cursor-pointer"
              onMouseEnter={() => setShowPrevButton(true)}
              onMouseLeave={() => setShowPrevButton(false)}
              onClick={(e) => {
                e.stopPropagation();
                onNavigatePrevious();
              }}
            >
              <button
                className={`absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-25 rounded-full p-2 transition-opacity duration-300 ${
                  showPrevButton ? "opacity-100 visible" : "opacity-0 invisible"
                }`}
              >
                <img src={leftArrow} alt="Previous" className="w-6 h-6" />
              </button>
            </div>

            <div
              className="absolute right-0 top-0 bottom-0 w-1/3 cursor-pointer"
              onMouseEnter={() => setShowNextButton(true)}
              onMouseLeave={() => setShowNextButton(false)}
              onClick={(e) => {
                e.stopPropagation();
                onNavigateNext();
              }}
            >
              <button
                className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-25 rounded-full p-2 transition-opacity duration-300 ${
                  showNextButton ? "opacity-100 visible" : "opacity-0 invisible"
                }`}
              >
                <img src={rightArrow} alt="Next" className="w-6 h-6" />
              </button>
            </div>
          </>
        )}

        <div
          className={`absolute top-0 bottom-0 ${
            hasMultipleImages ? "left-1/3 right-1/3" : "left-0 right-0"
          } cursor-pointer`}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default ImageModal;
