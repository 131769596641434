import React, { useEffect, useState, useCallback, useRef } from "react";
import { useUser } from "../context/UserContext";
import { BaseColor, UseBaseColorsProps } from "../types/types";
import debounce from "lodash/debounce"; // Make sure to install lodash if not already installed
import { log, showError } from '../utils/logger';
import { fetchBaseColors, refreshBaseColors } from '../utils/colorUtils'; // Ensure this import is present
import axios from 'axios'; // Import axios for making HTTP requests

interface ExtendedUseBaseColorsProps extends UseBaseColorsProps {
  selectedColors: string[];
  onColorsLoaded?: () => void;
  refreshKey?: number;
  appBaseColors: BaseColor[]; // Prop
  userBaseColors: BaseColor[]; // Prop
}

const UseBaseColors: React.FC<ExtendedUseBaseColorsProps> = ({
  onColorSelect,
  selectedColors,
  onColorsLoaded,
  refreshKey = 0,
  appBaseColors, // Prop
  userBaseColors, // Prop
}) => {
  const { connectedWalletAddress } = useUser();
  const isInitialMount = useRef(true);

  const storeProfileColors = useCallback(
    debounce((address: string, colors: string[]) => {
      if (colors.length < 2 || colors.some(color => !color)) {
        log('Invalid colors, not storing:', colors);
        return;
      }

      const [primary, secondary] = colors.map(color => encodeURIComponent(color));
      const url = `${process.env.REACT_APP_BACKEND}/basecolors/storeProfileColors/${address}?primary=${primary}&secondary=${secondary}`;
      log("Storing profile colors URL:", url);
      axios
        .get(url)
        .catch((error) =>
          showError("Error storing profile colors:", error)
        );
    }, 5000), // 5000ms (5 seconds) delay
    []
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (connectedWalletAddress && selectedColors.length >= 2 && selectedColors.every(color => color !== '')) {
      log('Storing colors:', selectedColors);
      storeProfileColors(connectedWalletAddress, selectedColors);
    }
  }, [selectedColors, storeProfileColors, connectedWalletAddress]);

  const fetchColors = useCallback(async (forceRefresh = false) => {
    // Fetch logic can be handled here if needed
  }, [connectedWalletAddress]);

  useEffect(() => {
    // Initial fetch logic can be handled here if needed
  }, [fetchColors]);

  useEffect(() => {
    log("App Base Colors:", appBaseColors);
    log("User Base Colors:", userBaseColors);
  }, [appBaseColors, userBaseColors]);

  const handleColorClick = (colorName: string) => {
    log('Color clicked:', colorName);
    onColorSelect([colorName]);
  };

  return (
    <div>
      <div className="flex flex-wrap">
        {appBaseColors.concat(userBaseColors).map((color, index) => {
          const tooltipText = color.colorName || color.name;
          return (
            <div
              key={index}
              className={`w-10 h-10 m-1 cursor-pointer relative ${
                selectedColors.includes(color.name) ? "border-2 border-white" : ""
              }`}
              style={{
                backgroundColor: color.name,
                border: '0px solid black', // Add a border for visibility
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)', // Optional shadow for depth
              }}
              title={tooltipText}
              onClick={() => handleColorClick(color.name)}
            >
              <span className="sr-only">{tooltipText}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UseBaseColors;