import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import { useTheme } from "../../context/ThemeContext";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import DailyThemeInfo from "../moshicam/DailyThemeInfo";
import GradientButton from "./GradientButton";
import UserDropdown from "./UserDropdown"; // Import UserDropdown
import SidebarNavLinks from "./SidebarNavLinks"; // Add this import

interface TopMenuProps {
  isMobile: boolean;
  text: string;
  onLinkClick: () => void;
  textColor: string; // Add this line
  isOpen: boolean; // Add this line
}

const TopMenu: React.FC<TopMenuProps> = ({ isMobile, text, onLinkClick, isOpen }) => {
  const { user, authenticated, login } = usePrivy();
  const { primaryColor } = useTheme(); // Add this line to get primaryColor from ThemeContext
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { textColor } = useTheme(); // Add this line to get textColor


  // Add this useEffect hook
  useEffect(() => {
    setDropdownOpen(true);
  }, []);

  const handleLinkClick = () => {
    if (onLinkClick) {
      onLinkClick();
    }
    // Remove the closeTopMenu() call as it will be handled by the parent component
  };

  if (!isOpen) {
    return null; // Don't render anything if the menu is closed
  }

  return (
    <nav
      className={`bg-gray-900 text-white flex flex-col items-center w-full ${
        isMobile ? 'h-[calc((100vh-0px)-0px)] overflow-y-auto pb-20' : 'h-auto'
      } fixed top-[60px] left-0 right-0`}
      style={{
        backgroundColor: primaryColor, // Use primaryColor from ThemeContext
        color: "white",
      }}
    >
      <div className="flex flex-col w-full h-full">

        <div
          className={`flex-col sm:flex-row flex ${
            dropdownOpen ? "block" : "hidden"
          } sm:flex items-center w-full h-full overflow-y-auto pb-20`}
        >
          <div className="w-full flex justify-center">
            <div className="w-[95%] mt-4 sm:mt-0 sm:ml-4">
              {/* Updated row for DailyThemeInfo and navigation */}
              <div className="flex flex-row justify-between items-start mb-4">
                <div className="w-[65%]">
                  <DailyThemeInfo  textColor={textColor}/>
                </div>
                <div className="w-[35%]">
                  <SidebarNavLinks onLinkClick={handleLinkClick} />
                </div>
              </div>
              
              {authenticated ? (
                <UserDropdown textColor={textColor} isMobile={isMobile} />
              ) : (
                <div>
                  <GradientButton
                    className="!px-8 !py-3 font-[tiny5] uppercase"
                    onClick={() => login()}
                  >
                    Log In / Sign Up
                  </GradientButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopMenu;