// /src/moshicam/SearchAndFilterControlsMoshicam.tsx

import React, { useState, useEffect } from "react";
import { FaSort, FaSyncAlt, FaSave } from "react-icons/fa";
import clearButton from "../../icons/clearButton.svg";
import searchIcon from "../../icons/search.svg"; 
import { Tooltip } from "react-tooltip";
import ConfirmationModal from "../ui/ConfirmationModal";
import { usePrivy } from "@privy-io/react-auth";
import confetti from "canvas-confetti";
import { SearchAndFilterControlsProps, GridControlsMoshicamProps } from "../../types/types";
import { log, showError } from '../../utils/logger';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');

const SearchAndFilterControlsMoshicam: React.FC<
  SearchAndFilterControlsProps &
    GridControlsMoshicamProps & {
      refreshNFTList: () => void;
      toggleSearchBar: () => void;
      setTooltipText: (text: string) => void; // Changed to accept string instead of string | null
      isMobile: boolean; // Added isMobile prop
      isRefetching: boolean; // Added isRefetching prop
      isInitialLoad: boolean; // Added isInitialLoad prop
      shouldRefresh: boolean; // Add this prop
    }
> = ({
  searchQuery,
  setSearchQuery,
  toggleSortMethod,
  sortMethod,
  clearSearchQuery,
  nftsLength,
  onSave,
  canSave,
  refreshNFTList,
  toggleSearchBar,
  setTooltipText,
  isMobile, // Added isMobile prop
  isRefetching, // Added isRefetching prop
  isInitialLoad, // Added isInitialLoad prop
  shouldRefresh, // Add this prop
}) => {
  const { user, authenticated } = usePrivy();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isDisabled = !authenticated;

  useEffect(() => {
    if (shouldRefresh) {
      handleRefreshCollection();
    }
  }, [shouldRefresh]);

  const getSortMethodTooltip = (method: string, isMobile: boolean) => {
    if (isMobile) {
      switch (method) {
        case 'description':
          return 'Sort By: Recent';
        case 'recent':
          return 'Sort By: Oldest';
        case 'oldest':
          return 'Sort By: Border';
        case 'borderId':
          return 'Sort By: Description';
        default:
          return 'Sort';
      }
    } else {
      switch (method) {
        case 'description':
          return 'Sort by: Description';
        case 'recent':
          return 'Sort by: Recent';
        case 'oldest':
          return 'Sort by: Oldest';
        case 'borderId':
          return 'Sort by: Border';
        default:
          return 'Sort';
      }
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;
    const searchValue = e.target.value.toLowerCase();
    setSearchQuery(searchValue);
  };

  const handleClearSearch = () => {
    if (isDisabled) return;
    setSearchQuery("");
  };

  const handleRefreshCollection = async () => {
    if (isDisabled) return;
    setIsRefreshing(true);
    await handleFetchAndStoreNFTs();
    await refreshNFTList(); // This will now trigger both the database update and the UI refresh
    setIsRefreshing(false);
  };

  const handleFetchAndStoreNFTs = async () => {
    const address = user?.wallet?.address;
    if (!address) {
      alert("User wallet address is not available.");
      setIsRefreshing(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/moshicam/fetchNFTsFromAlchemyAndStore?address=${address}`
      );
      if (!response.ok) {
        throw new Error("Failed to find photos");
      }
      const nftData = await response.json();
      log(nftData);
    } catch (error) {
      showError("Error finding photos:", error);
      alert(`Failed to find photos: ${(error as Error).message}`);
    }
  };

  const handleSortClick = () => {
    if (isDisabled) return;
    if (window.innerWidth <= 1000) {
      setTooltipText(getSortMethodTooltip(sortMethod, true));
    }
    toggleSortMethod();
  };

  const handleRefreshClick = () => {
    if (isDisabled) return;
    if (window.innerWidth <= 1000) {
      setTooltipText("Refresh Collection");
    }
    handleRefreshCollection();
  };

  const handleSaveClick = () => {
    if (isDisabled) return;
    if (window.innerWidth <= 1000) {
      setTooltipText("Save");
    }
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    onSave();
    setIsModalOpen(false);
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      zIndex: 9500, // Ensure confetti appears above everything else
    });
  };

  return (
    <div className="w-full max-w-2xl mx-auto mt-0">
      {/* Mobile View: 3x3 Grid Layout with off-screen tabs */}
      <div className={isMobile ? "sm:hidden" : "hidden"}>
        {/* Top Left Cell: Search Icon Button */}
        <div className="absolute left-0 top-2 -ml-2">
          <div className={`bg-white bg-opacity-15 flex items-center justify-center h-[100px] w-[60px] rounded-r-md ${isDisabled ? 'opacity-50' : ''}`}>
            <button onClick={toggleSearchBar} className="w-6 h-6 flex items-center justify-center pl-2" disabled={isDisabled}>
              <img src={searchIcon} alt="Search" className="w-full h-full" />
            </button>
          </div>
        </div>

        {/* Top Right Cell: Sort Button */}
        <div className="absolute right-0 top-2 -mr-2">
          <div className={`bg-white bg-opacity-15 flex items-center justify-center h-[100px] w-[60px] rounded-l-md ${isDisabled ? 'opacity-50' : ''}`}>
            <FaSort
              className={`w-6 h-6 ${isDisabled ? 'text-gray-500 cursor-not-allowed' : 'text-white cursor-pointer'}`}
              onClick={isDisabled ? undefined : toggleSortMethod}
              data-tooltip-id="sort-tooltip"
              data-tooltip-content={getSortMethodTooltip(sortMethod, true)}
            />
          </div>
        </div>

        {/* Bottom Left Cell: Refresh Button */}
        <div className="absolute left-0 bottom-9 -ml-2">
          <div className={`bg-white bg-opacity-15 flex items-center justify-center h-[100px] w-[60px] rounded-r-md ${isDisabled ? 'opacity-50' : ''}`}>
            <FaSyncAlt
              className={`w-5 h-5 ${isDisabled ? 'text-gray-500 cursor-not-allowed' : 'text-white cursor-pointer'}`}
              onClick={isDisabled ? undefined : handleRefreshClick}
              data-tooltip-id="refresh-tooltip"
              data-tooltip-content="Refresh Collection"
            />
          </div>
        </div>

        {/* Bottom Right Cell: Save Button */}
        <div className="absolute right-0 bottom-9 -mr-2">
          <div className={`bg-white bg-opacity-15 flex items-center justify-center h-[100px] w-[60px] rounded-l-md ${isDisabled ? 'opacity-50' : ''}`}>
            <FaSave
              className={`w-6 h-6 ${
                isDisabled || !canSave
                  ? 'text-gray-500 cursor-not-allowed'
                  : 'text-green-500 animate-pulse cursor-pointer'
              }`}
              onClick={isDisabled ? undefined : canSave ? handleSaveClick : undefined}
              aria-disabled={isDisabled || !canSave}
            />
          </div>
        </div>
      </div>

      {/* Desktop View: Original Layout */}
      <div className={isMobile ? "hidden" : "hidden sm:flex items-center justify-center mt-0 w-full"}>
        {/* Search Bar and Buttons Container */}
        <div className="flex items-center space-x-4 w-[90%] max-w-3xl">
          {/* Search Bar */}
          <div className="flex-grow relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={`Search ${nftsLength} items`}
              className={`text-center bg-gray-800 p-2 font-['Jersey_15'] uppercase text-2xl rounded-md w-full ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isDisabled}
            />
            {searchQuery.length > 0 && !isDisabled && (
              <img
                src={clearButton}
                alt="Clear"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
                onClick={handleClearSearch}
              />
            )}
          </div>

          {/* Sort Button */}
          <div className="flex items-center">
            <FaSort
              className={`w-6 h-6 ${isDisabled ? 'text-gray-500 cursor-not-allowed' : 'text-white cursor-pointer'}`}
              onClick={isDisabled ? undefined : toggleSortMethod}
              data-tooltip-id="sort-tooltip"
              data-tooltip-content={getSortMethodTooltip(sortMethod, false)}
            />
            <Tooltip id="sort-tooltip" className="font-[tiny5]" />
          </div>

          {/* Refresh Button */}
          <div className="flex items-center">
            <FaSyncAlt
              className={`w-6 h-6 ${isDisabled ? 'text-gray-500 cursor-not-allowed' : 'text-white cursor-pointer'}`}
              onClick={isDisabled ? undefined : handleRefreshCollection}
              data-tooltip-id="refresh-tooltip"
              data-tooltip-content="Refresh Collection"
            />
            <Tooltip id="refresh-tooltip" className="font-[tiny5]" />
          </div>

          {/* Save Button */}
          <div className="flex items-center">
            <FaSave
              className={`w-6 h-6 ${isDisabled ? 'text-gray-500 cursor-not-allowed' : canSave ? "text-green-500 animate-pulse cursor-pointer" : "text-gray-500 cursor-not-allowed"}`}
              onClick={isDisabled || !canSave ? undefined : handleSaveClick}
              data-tooltip-id="save-tooltip"
              data-tooltip-content="Save"
              aria-disabled={isDisabled || !canSave}
            />
            <Tooltip id="save-tooltip" className="font-[tiny5]" />
          </div>
        </div>

        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleConfirm}
          message="Are you sure you want to place this item on the grid?"
        />
      </div>
    </div>
  );
};

export default SearchAndFilterControlsMoshicam;