import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client'; // Import io from socket.io-client
import SingleGridMoshicam from '../../components/moshicam/SingleGridMoshicam';
import { useGridView } from '../../context/GridViewContext';
import { log, showError } from '../../utils/logger';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');

interface GridData {
  _id: string;
  description: string;
  gridColumns: number;
  name: string;
  items: {
    [key: string]: {
      contract: string;
      description: string;
      tokenId: number | string;
      name: string;
      originalCreator: string;
      image: string;
      chainId: number;
      placedby: string;
    }
  };
  imageUrl: string | null;
  imageUrlOriginal: string | null;
  tokenURI: string | null;
}

interface FeedMoshicamProps {
  dayNumber: string;
}

const FeedMoshicam: React.FC<FeedMoshicamProps> = ({ dayNumber }) => {
  const [grids, setGrids] = useState<GridData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isGridViewEnabled } = useGridView();
  const [isCurrentDay, setIsCurrentDay] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDayInfo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/moshicam/getDayNumber`);
        const { dayNumber: currentDayNumber, dayDate } = response.data;
        
        log('API dayNumber:', currentDayNumber);
        log('Component dayNumber:', dayNumber);
        log('API dayDate:', dayDate);

        setIsCurrentDay(currentDayNumber.toString() === dayNumber);
      } catch (error) {
        showError('Error fetching day info:', error);
      }
    };

    fetchDayInfo();
  }, [dayNumber]);

  useEffect(() => {
    log('useEffect called, dayNumber:', dayNumber, 'isCurrentDay:', isCurrentDay);

    const fetchGrids = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/moshicam/fetchDay?number=${dayNumber}`);
        const sortedGrids = response.data.sort((a: GridData, b: GridData) => {
          const [aDay, aNumber] = a._id.split(':').map(Number);
          const [bDay, bNumber] = b._id.split(':').map(Number);
          if (aDay !== bDay) {
            return bDay - aDay; // Compare day numbers first
          }
          return bNumber - aNumber; // Then compare grid numbers
        });

        if (sortedGrids.length === 0) {
          navigate('/moshicam/feed');
        } else {
          setGrids(sortedGrids);
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch grids');
        setLoading(false);
        navigate('/moshicam/feed');
      }
    };

    fetchGrids();

    if (isCurrentDay) {
      log('Attempting to connect to WebSocket');
      const backendUrl = process.env.REACT_APP_BACKEND;
      if (typeof backendUrl === 'string') {
        const socket = io(backendUrl);

        socket.on('connect', () => {
          log('Connected to WebSocket server');
        });

        socket.on('disconnect', () => {
          log('Disconnected from WebSocket server');
        });

        socket.on('gridUpdate', (gridData: GridData) => {
          log('Received gridUpdate event', gridData);
          
          setGrids(prevGrids => {
            const updatedGrids = [gridData, ...prevGrids.filter(grid => grid._id !== gridData._id)];
            
            // Schedule a refresh of the second grid (previous current grid) after 2 seconds
            if (updatedGrids.length > 1) {
              setTimeout(async () => {
                try {
                  const [dayNumber, gridNumber] = updatedGrids[1]._id.split(':');
                  // Updated line to use process.env.REACT_APP_BACKEND
                  const response = await axios.get(`${process.env.REACT_APP_BACKEND}/moshicam/fetchGrid?dayNumber=${dayNumber}&gridNumber=${gridNumber}`);
                  setGrids(currentGrids => [
                    currentGrids[0],
                    response.data,
                    ...currentGrids.slice(2)
                  ]);
                } catch (error) {
                  showError('Error refreshing previous grid:', error);
                }
              }, 2000);
            }
            
            return updatedGrids;
          });
        });

        socket.on('connect_error', (error: any) => {
          showError('WebSocket connection error:', error);
        });

        return () => {
          log('Disconnecting from WebSocket');
          socket.disconnect();
        };
      } else {
        showError("REACT_APP_BACKEND is not defined or not a string");
      }
    }
  }, [dayNumber, isCurrentDay, navigate]);

  // Filter and sort grids
  const filteredAndSortedGrids = React.useMemo(() => {
    return grids
      .filter(grid => isCurrentDay || Object.keys(grid.items).length === 9)
      .sort((a, b) => {
        const [aDay, aNumber] = a._id.split(':').map(Number);
        const [bDay, bNumber] = b._id.split(':').map(Number);
        if (aDay !== bDay) {
          return bDay - aDay; // Compare day numbers first
        }
        return bNumber - aNumber; // Then compare grid numbers
      });
  }, [grids, isCurrentDay]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="feed-moshicam mt-[45px] sm:mt-0">
      {filteredAndSortedGrids.map((grid) => (
        <div 
          key={grid._id}
          className={`grid-container ${isGridViewEnabled ? 'mb-10' : 'mb-0'}`}
        >
          <SingleGridMoshicam gridData={grid} />
        </div>
      ))}
    </div>
  );
};

export default FeedMoshicam;