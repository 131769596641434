import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { BaseColor } from '../types/types';
import { fetchBaseColors, refreshBaseColors } from '../utils/colorUtils';
import { usePrivy } from "@privy-io/react-auth"; // Import usePrivy to get user info

interface ColorContextType {
  primaryColor: string | null;
  secondaryColor: string | null;
  setPrimaryColor: (color: string | null) => void;
  setSecondaryColor: (color: string | null) => void;
  appBaseColors: BaseColor[];
  userBaseColors: BaseColor[];
  fetchColors: (forceRefresh?: boolean) => Promise<void>;
}

const ColorContext = createContext<ColorContextType | undefined>(undefined);

export const ColorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = usePrivy(); // Get user info from Privy
  const [primaryColor, setPrimaryColor] = useState<string | null>(null);
  const [secondaryColor, setSecondaryColor] = useState<string | null>(null);
  const [appBaseColors, setAppBaseColors] = useState<BaseColor[]>([]);
  const [userBaseColors, setUserBaseColors] = useState<BaseColor[]>([]);

  const fetchColors = useCallback(async (forceRefresh = false) => {
    const appWalletAddress = process.env.REACT_APP_THEGRID_WALLET_ADDRESS;
    if (appWalletAddress && (forceRefresh || appBaseColors.length === 0)) {
      const colors = forceRefresh 
        ? await refreshBaseColors(appWalletAddress)
        : await fetchBaseColors(appWalletAddress);
      setAppBaseColors(colors);
    }
    const userWalletAddress = user?.wallet?.address; // Get the actual user wallet address
    if (userWalletAddress && (forceRefresh || userBaseColors.length === 0)) {
      const colors = forceRefresh 
        ? await refreshBaseColors(userWalletAddress)
        : await fetchBaseColors(userWalletAddress);
      setUserBaseColors(colors);
    }
  }, [appBaseColors.length, userBaseColors.length, user?.wallet?.address]);

  return (
    <ColorContext.Provider value={{
      primaryColor,
      secondaryColor,
      setPrimaryColor,
      setSecondaryColor,
      appBaseColors,
      userBaseColors,
      fetchColors
    }}>
      {children}
    </ColorContext.Provider>
  );
};

export const useColor = () => {
  const context = useContext(ColorContext);
  if (!context) {
    throw new Error('useColor must be used within a ColorProvider');
  }
  return context;
};