import React, { useState } from "react";
import leftArrow from "../../icons/leftArrow.svg";
import rightArrow from "../../icons/rightArrow.svg";
import { ImageModalProps } from "../../types/types";

const ImageModal: React.FC<ImageModalProps> = ({
  images,
  currentIndex,
  onClose,
  onNavigateNext,
  onNavigatePrevious,
}) => {
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const currentImage = images[currentIndex];
  if (!currentImage) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: -12,
        left: 7,
        width: "335px",
        height: "425px",
        backgroundColor: "rgba(0, 0, 0, 0)", // Set a transparent background
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
        cursor: "pointer",
      }}
      onClick={onClose}
    >
      <div
        style={{
          position: "relative",
          maxWidth: "360px",
          maxHeight: "385px",
          margin: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={currentImage.image}
          alt="Expanded view"
          style={{
            width: "360px",
            cursor: "pointer",
          }}
        />

        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: "33.33%",
            cursor: "pointer",
          }}
          onMouseEnter={() => setShowPrevButton(true)}
          onMouseLeave={() => setShowPrevButton(false)}
          onClick={(e) => {
            e.stopPropagation();
            onNavigatePrevious();
          }}
        >
          <button
            style={{
              position: "absolute",
              left: "0.5rem",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              borderRadius: "9999px",
              padding: "0.5rem",
              opacity: showPrevButton ? 1 : 0,
              visibility: showPrevButton ? "visible" : "hidden",
              transition: "opacity 0.3s, visibility 0.3s",
              zIndex: 10,
            }}
          >
            <img src={leftArrow} alt="Previous" className="w-6 h-6" />
          </button>
        </div>

        <div
          style={{
            position: "absolute",
            left: "33.33%",
            top: 0,
            bottom: 0,
            width: "33.33%",
            cursor: "pointer",
          }}
          onClick={onClose}
        />

        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: "33.33%",
            cursor: "pointer",
          }}
          onMouseEnter={() => setShowNextButton(true)}
          onMouseLeave={() => setShowNextButton(false)}
          onClick={(e) => {
            e.stopPropagation();
            onNavigateNext();
          }}
        >
          <button
            style={{
              position: "absolute",
              right: "0.5rem",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              borderRadius: "9999px",
              padding: "0.5rem",
              opacity: showNextButton ? 1 : 0,
              visibility: showNextButton ? "visible" : "hidden",
              transition: "opacity 0.3s, visibility 0.3s",
              zIndex: 10,
            }}
          >
            <img src={rightArrow} alt="Next" className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
