import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext'; // Add this import

interface SidebarHeaderProps {
  isMobile: boolean;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = React.memo(({ isMobile }) => {
  const { secondaryColor, textColor } = useTheme(); // Use the useTheme hook

  const linkStyle = {
    color: textColor,
    transition: 'color 0.3s ease',
  };

  return (
    <Link
      to="/"
      className={`font-bold ${
        isMobile ? 'text-2xl' : 'text-xl mb-4'
      } block uppercase font-tiny5 ${isMobile ? 'mx-auto' : ''}`}
      style={linkStyle}
      onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
      onMouseLeave={(e) => e.currentTarget.style.color = textColor}
    >
      The Grid
    </Link>
  );
});

export default SidebarHeader;