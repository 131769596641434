// src/context/ThemeContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { ThemeContextType, BaseColor } from "../types/types";
import { fetchUserProfileColors, storeUserProfileColors, getContrastColor } from '../utils/themeUtils';
import { usePrivy } from "@privy-io/react-auth";
import debounce from 'lodash.debounce';
import { log, showError } from "../utils/logger";


// Instead of console.log
// log('This is a log message');
// Instead of console.error
// showError('This is an error message');

const ThemeContext = createContext<ThemeContextType>({
  primaryColor: "#121826",
  secondaryColor: "#ac0374",
  textColor: "#FFFFFF", // Add this line
  selectedColors: [],
  setPrimaryColor: () => {},
  setSecondaryColor: () => {},
  setSelectedColors: () => {},
  fetchAndSetUserColors: async () => [], // Update this line
});

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = usePrivy();
  const [primaryColor, setPrimaryColor] = useState<string>("#121826");
  const [secondaryColor, setSecondaryColor] = useState<string>("#ac0374");
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [textColor, setTextColor] = useState<string>("#FFFFFF");

  const fetchAndSetUserColors = useCallback(async (address: string): Promise<BaseColor[]> => {
    try {
      const { primary, secondary } = await fetchUserProfileColors(address);
      setPrimaryColor(primary);
      setSecondaryColor(secondary);

      return [
        { hex: primary, name: "Primary Color", colorName: "Primary" },
        { hex: secondary, name: "Secondary Color", colorName: "Secondary" }
      ];
    } catch (error) {
      showError("Error fetching user profile colors:", error);
      return [];
    }
  }, []);

  const debouncedStoreColors = useCallback(
    debounce(async (address: string, primary: string, secondary: string) => {
      if (address) {
        try {
          await storeUserProfileColors(address, primary, secondary);
        } catch (error) {
          showError("Error storing user profile colors:", error);
        }
      }
    }, 5000),
    []
  );

  const handleSetPrimaryColor = useCallback((color: string) => {
    setPrimaryColor(color);
    setTextColor(getContrastColor(color)); // Add this line
    log("ThemeContext - Primary Color:", color);
    if (user?.wallet?.address) {
      debouncedStoreColors(user.wallet.address, color, secondaryColor);
    }
  }, [secondaryColor, debouncedStoreColors, user?.wallet?.address]);

  const handleSetSecondaryColor = useCallback((color: string) => {
    setSecondaryColor(color);
    log("ThemeContext - Secondary Color:", color);
    if (user?.wallet?.address) {
      debouncedStoreColors(user.wallet.address, primaryColor, color);
    }
  }, [primaryColor, debouncedStoreColors, user?.wallet?.address]);

  useEffect(() => {
    if (user?.wallet?.address) {
      fetchAndSetUserColors(user.wallet.address);
    }
  }, [user?.wallet?.address, fetchAndSetUserColors]);

  useEffect(() => {
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty("--secondary-color", secondaryColor);
    document.documentElement.style.setProperty("--text-color", textColor); // Add this line
  }, [primaryColor, secondaryColor, textColor]); // Add textColor to dependencies

  return (
    <ThemeContext.Provider
      value={{
        primaryColor,
        secondaryColor,
        textColor, // Add this line
        selectedColors,
        setPrimaryColor: handleSetPrimaryColor,
        setSecondaryColor: handleSetSecondaryColor,
        setSelectedColors,
        fetchAndSetUserColors,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
