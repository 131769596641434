import React, { useState } from 'react';
import axios from 'axios';
import { usePrivy } from "@privy-io/react-auth";
import { useEnsLookup } from "../../hooks/useEnsLookup";
import { useTheme } from "../../context/ThemeContext"; // Add this import
import GradientButton from "../../components/ui/GradientButton";
import WalletConnect from "../../components/WalletConnect";

const Feedback: React.FC = () => {
  const [feedbackType, setFeedbackType] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const { user, authenticated, login } = usePrivy();
  const { textColor } = useTheme(); // Add this line to get textColor

  const { ensName, resolvedAddress } = useEnsLookup(user?.wallet?.address);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!authenticated) {
      setStatus('Please log in to submit feedback.');
      return;
    }
    setStatus('Submitting...');

    try {
      const feedbackData = {
        feedbackType,
        message,
        walletAddress: resolvedAddress || user?.wallet?.address,
        ensName: ensName || null,
      };
      await axios.post(`${process.env.REACT_APP_BACKEND}/moshicam/submitFeedback`, feedbackData);
      setStatus('Feedback submitted successfully!');
      setFeedbackType('');
      setMessage('');
    } catch (error) {
      setStatus('Error submitting feedback. Please try again.');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen p-4">
      <div className="w-full max-w-2xl p-6 bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">Beta Feedback</h2>
        
        {authenticated ? (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="feedbackType" className="block mb-2 font-medium text-white">Feedback Type:</label>
              <select
                id="feedbackType"
                value={feedbackType}
                onChange={(e) => setFeedbackType(e.target.value)}
                required
                className="w-full p-3 text-lg bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select a type</option>
                <option value="bug">Bug Report</option>
                <option value="feature">Feature Request</option>
                <option value="general">General Feedback</option>
              </select>
            </div>
            <div>
              <label htmlFor="message" className="block mb-2 font-medium text-white">Message:</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                className="w-full p-3 text-lg bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows={6}
              ></textarea>
            </div>
            <GradientButton 
              type="submit" 
              className="w-full py-3 px-4 text-lg font-medium"
            >
              Submit Feedback
            </GradientButton>
          </form>
        ) : (
          <div className="text-center">
            <p className="mb-4 text-white">Please log in to submit feedback.</p>
            <WalletConnect textColor={textColor} /> {/* Pass textColor here */}
          </div>
        )}
        {status && <p className="mt-4 text-center font-bold text-white">{status}</p>}
      </div>
    </div>
  );
};

export default Feedback;