import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import FeedMoshicam from './feedSingleDay';
import { useInView } from 'react-intersection-observer';
import { log, showError } from '../../utils/logger';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// showError('This is an error message');

const FeedAllDays: React.FC = () => {
  const [currentDayNumber, setCurrentDayNumber] = useState<number | null>(null);
  const [loadedDays, setLoadedDays] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const fetchCurrentDayNumber = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/moshicam/getDayNumber`);
      const { dayNumber } = response.data;
      setCurrentDayNumber(parseInt(dayNumber));
      setLoadedDays([parseInt(dayNumber), parseInt(dayNumber) - 1]);
      setLoading(false);
    } catch (error) {
      showError('Error fetching current day number:', error);
      setError('Failed to fetch current day number');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCurrentDayNumber();
  }, [fetchCurrentDayNumber]);

  useEffect(() => {
    if (inView && currentDayNumber !== null && loadedDays.length < currentDayNumber) {
      const nextDay = loadedDays[loadedDays.length - 1] - 1;
      if (nextDay > 0) {
        setLoadedDays(prevDays => [...prevDays, nextDay]);
      }
    }
  }, [inView, currentDayNumber, loadedDays]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="feed-all-moshicam mt-[45px] sm:mt-[35px]">
      {loadedDays.map((day, index) => (
        <React.Fragment key={day}>
          <FeedMoshicam dayNumber={day.toString()} />
          {index === loadedDays.length - 1 && (
            <div ref={ref} style={{ height: '20px' }}></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default FeedAllDays;
