import React, { useState } from 'react';
import axios from 'axios';
import { usePrivy } from "@privy-io/react-auth";
import { useEnsLookup } from "../../hooks/useEnsLookup";
import { useTheme } from "../../context/ThemeContext";
import GradientButton from "../../components/ui/GradientButton";
import WalletConnect from "../../components/WalletConnect";

const SubmitTheme: React.FC = () => {
  const [themeName, setThemeName] = useState('');
  const [status, setStatus] = useState('');
  const { user, authenticated, login } = usePrivy();
  const { textColor } = useTheme();

  const { ensName, resolvedAddress } = useEnsLookup(user?.wallet?.address);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!authenticated) {
      setStatus('Please log in to submit a theme.');
      return;
    }
    if (!themeName.trim()) {
      setStatus('Please enter a theme name.');
      return;
    }
    setStatus('Submitting...');

    const themeData = {
      themeName: themeName.trim(),
      submittedBy: resolvedAddress || user?.wallet?.address,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/moshicam/createTheme`, themeData);
      setStatus('Theme submitted successfully!');
      setThemeName('');
    } catch (error) {
      setStatus('Error submitting theme. Please try again.');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen p-4">
      <div className="w-full max-w-2xl p-6 bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">Submit a Theme</h2>
        
        {authenticated ? (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="themeName" className="block mb-2 font-medium text-white">Theme Name:</label>
              <input
                id="themeName"
                value={themeName}
                onChange={(e) => setThemeName(e.target.value)}
                required
                className="w-full p-3 text-lg bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <GradientButton 
              type="submit" 
              className="w-full py-3 px-4 text-lg font-medium"
            >
              Submit Theme
            </GradientButton>
          </form>
        ) : (
          <div className="text-center">
            <p className="mb-4 text-white">Please log in to submit a theme.</p>
            <WalletConnect textColor={textColor} />
          </div>
        )}
        {status && <p className="mt-4 text-center font-bold text-white">{status}</p>}
      </div>
    </div>
  );
};

export default SubmitTheme;