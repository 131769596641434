import axios from 'axios';
import { BaseColor } from '../types/types';
import { log, showError } from "./logger";


export const fetchBaseColors = async (address: string): Promise<BaseColor[]> => {
  try {
    log("Fetching from DB for address:", address);
    let response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/basecolors/fetchNFTsFromdb?address=${address}`
    );
    let data = response.data;

    if (!data || data.length === 0) {
      log("DB fetch returned empty, fetching from Alchemy for address:", address);
      response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/basecolors/fetchNFTsFromAlchemyAndStore?address=${address}`
      );
      data = response.data;
    } else {
      log("Data fetched from DB for address:", address);
    }

    // If we have data, process it
    if (data && data.length > 0) {
      const colors = data.map((item: any) => {
        const name = item.raw.metadata.name;
        const colorName =
          item.raw.metadata.attributes.find(
            (attr: any) => attr.trait_type === "Color Name"
          )?.value || name;

        const addHashIfHex = (color: string) => {
          return color.match(/^[0-9A-Fa-f]{6}$/) ? `#${color}` : color;
        };

        return {
          name: addHashIfHex(name),
          colorName: addHashIfHex(colorName),
        };
      });
      return colors;
    }
    return [];
  } catch (error) {
    showError("Error fetching base colors:", error);
    return [];
  }
};

export const refreshBaseColors = async (address: string): Promise<BaseColor[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/basecolors/fetchNFTsFromAlchemyAndStore?address=${address}`
    );
    const data = response.data;

    if (data && data.length > 0) {
      const colors = data.map((item: any) => {
        const name = item.raw.metadata.name;
        const colorName =
          item.raw.metadata.attributes.find(
            (attr: any) => attr.trait_type === "Color Name"
          )?.value || name;

        const addHashIfHex = (color: string) => {
          return color.match(/^[0-9A-Fa-f]{6}$/) ? `#${color}` : color;
        };

        return {
          name: addHashIfHex(name),
          colorName: addHashIfHex(colorName),
        };
      });
      return colors;
    }
    return [];
  } catch (error) {
    showError("Error refreshing base colors:", error);
    return [];
  }
};