// pages/api/fetchNfts.tsx
import axios from "axios";
import { NFT } from "../types/types"; // Import interfaces from types.ts
import { log, showError } from '../utils/logger';

// Instead of log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');

export async function fetchNFTs(address: string): Promise<NFT[]> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/moshicam/fetchNFTsFromDB`, // Updated to use environment variable
      {
        params: { address },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to refresh photo collection");
    }

    const data = response.data;
    log("Data received from API:", data); // Debug log

    // Directly return the data as it's already an array of NFTs
    return data.map((nft: NFT) => ({
      ...nft,
      chainId: Number(nft.chainId),
      labels: nft.metadata?.properties?.labels?.map((label: { description: string }) => label.description) || [],
    }));
  } catch (error) {
    showError("Error finding photos:", error);
    throw error;
  }
}