import React, { useState, useEffect } from 'react';
import moshicamLogo from '../icons/moshicam-logo.png'; // Import the image
import { useTheme } from '../context/ThemeContext'; // Import the useTheme hook
import GradientButton from '../components/ui/GradientButton';
import { Link } from 'react-router-dom'; // Import Link
import { fetchThemeInfo } from '../components/moshicam/DailyThemeInfo'; // Import the fetch function

const Help: React.FC = () => {
    const { secondaryColor } = useTheme(); // Get the secondary color from ThemeContext
    const [themeName, setThemeName] = useState<string>('');
    const [submittedBy, setSubmittedBy] = useState<string>('');

    useEffect(() => {
        const getThemeInfo = async () => {
            const themeInfo = await fetchThemeInfo();
            if (themeInfo) {
                setThemeName(themeInfo.themeName);
                setSubmittedBy(themeInfo.submittedBy);
            }
        };

        getThemeInfo();
    }, []);

    return (
        <>
            <style>
                {`
                    @media (max-width: 768px) {
                        .mobile-margin-top {
                            margin-top: 60px;
                        }
                    }
                `}
            </style>
            <div className="flex flex-col items-center justify-center mobile-margin-top"> {/* Added mobile-margin-top class */}
                <h1 className="font-bold text-4xl mb-4 uppercase font-tiny5 text-white">
                    How it works:
                </h1>
                <ol className="text-white text-2xl font-['Jersey_15'] uppercase">
                    <li className="mb-2">
                        <div className="flex flex-col items-center mb-8"> {/* Added mb-8 for more space */}
                            <span>Create or Collect Photos</span>
                            <div className="flex items-center">
                                <span>via</span>
                                <a href="https://moshi.cam" target="_blank" rel="noopener noreferrer" className="flex items-center">
                                    <img src={moshicamLogo} alt="Moshicam Logo" className="w-6 h-6 mx-2" />
                                    Moshicam
                                </a>
                            </div>
                            <span className="text-white text-2xl font-['Jersey_15'] uppercase mt-8 text-center">
                                Today's Theme:<br />
                                <span style={{ color: secondaryColor }}>{themeName}</span>
                            </span> {/* Centered text and applied secondary color to themeName */}
                        </div>
                    </li>
                    <li className="mb-2">
                        <div className="flex flex-col items-center">
                            <span className="mt-2 mb-2">Add Photos To The Grid</span> {/* Added mb-4 for space */}
                            <ul className="list-disc list-inside mb-4">
                                <li className="mb-2">
                                    <span style={{ color: secondaryColor }}>Choose</span>
                                    <span> a spot on </span>
                                    <a href="/moshicam" style={{ color: secondaryColor }}>
                                        Today's grid
                                    </a>
                                </li>
                                <li className="mb-2"><span style={{ color: secondaryColor }}>Select a Photo</span> from your collection</li>
                                <li className="mb-2"><span style={{ color: secondaryColor }}>Hit Save</span> to contribute</li>
                            </ul>
                        </div>
                    </li>
                </ol>
                <div className="mt-[-20px]">
                    <Link to="/moshicam">
                        <GradientButton className="!px-8 !py-3">
                            See Today's Grid
                        </GradientButton>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Help;