import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { log, showError } from '../utils/logger';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');


interface MobileContextType {
  isMobile: boolean;
  checkMobile: () => void;
}

const MobileContext = createContext<MobileContextType | undefined>(undefined);

export const MobileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  const checkMobile = useCallback(() => {
    const newIsMobile = window.innerWidth <= 1000;
    if (newIsMobile !== isMobile) {
      setIsMobile(newIsMobile);
      log('isMobile state changed:', newIsMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [checkMobile]);

  return (
    <MobileContext.Provider value={{ isMobile, checkMobile }}>
      {children}
    </MobileContext.Provider>
  );
};

export const useMobile = () => {
  const context = useContext(MobileContext);
  if (context === undefined) {
    throw new Error('useMobile must be used within a MobileProvider');
  }
  return context;
};