// /src/utils/cache.ts
class Cache {
    private cache: Map<string, any>;
    private static instance: Cache;
  
    private constructor() {
      this.cache = new Map<string, any>();
    }
  
    static getInstance(): Cache {
      if (!Cache.instance) {
        Cache.instance = new Cache();
      }
      return Cache.instance;
    }
  
    get(key: string): any {
      return this.cache.get(key);
    }
  
    set(key: string, value: any): void {
      this.cache.set(key, value);
    }
  }
  
  export const cache = Cache.getInstance();
  