// /src/components/WalletConnect.tsx

import React from 'react';
import { usePrivy } from "@privy-io/react-auth";
import GradientButton from './ui/GradientButton';

interface WalletConnectProps {
  textColor: string; // Add this line
}

const WalletConnect: React.FC<WalletConnectProps> = () => {
  const { ready, authenticated, login, logout } = usePrivy();

  if (!ready) {
    return <div className="flex flex-col items-center justify-center min-h-screen"><p>Loading...</p></div>;
  }

  if (!authenticated) {
    return (
      <div>
        <GradientButton onClick={login} className="!px-8 !py-3">
          Log In / Sign Up
        </GradientButton>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <p className="mb-4 text-white">Wallet connected</p>
      <GradientButton onClick={logout}>
        Log Out
      </GradientButton>
    </div>
  );
};

export default WalletConnect;