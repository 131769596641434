import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { log, showError } from '../../utils/logger';
import { useTheme } from '../../context/ThemeContext';
import MoshicamLogo from '../../icons/moshicam-logo.png';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// showError('This is an error message');

interface ThemeInfo {
  dayNumber: number;
  dayDate: string;
  themeName: string;
  submittedBy: string;
  serverTime: string;
  localTime: string;
}

interface DailyThemeInfoProps {
  textColor: string;
}

export const fetchThemeInfo = async (): Promise<ThemeInfo | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/moshicam/getDayNumber`
    );
    return response.data;
  } catch (error) {
    showError('Error fetching theme info:', error);
    return null;
  }
};

const DailyThemeInfo: React.FC<DailyThemeInfoProps> = ({ textColor }) => {
  const [themeInfo, setThemeInfo] = useState<ThemeInfo | null>(null);
  const [countdown, setCountdown] = useState<string>('');
  const { secondaryColor } = useTheme();

  useEffect(() => {
    const fetchAndSetThemeInfo = async () => {
      const data = await fetchThemeInfo();
      setThemeInfo(data);
    };

    fetchAndSetThemeInfo();
    const interval = setInterval(fetchAndSetThemeInfo, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const calculateCountdown = () => {
      if (themeInfo) {
        const serverTime = new Date(themeInfo.serverTime);
        const nextThemeTime = new Date(serverTime);
        nextThemeTime.setUTCHours(7, 0, 0, 0);

        // If server time is past 7 AM UTC, set next theme time to 7 AM UTC the next day
        if (serverTime.getUTCHours() >= 7) {
          nextThemeTime.setUTCDate(nextThemeTime.getUTCDate() + 1);
        }

        const diff = nextThemeTime.getTime() - serverTime.getTime();
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        setCountdown(`${hours}h ${minutes}m`);
      }
    };

    calculateCountdown();
    const interval = setInterval(calculateCountdown, 60000); // Update countdown every minute

    return () => clearInterval(interval);
  }, [themeInfo]);

  if (!themeInfo) return null;

  return (
    <div className="bg-white bg-opacity-10 p-4 shadow-md">
      <div className="flex justify-center items-center mb-2">
        <p className="text-lg font-semibold mr-2" style={{ color: secondaryColor }}>Moshicam</p>
        <a href="https://www.moshi.cam" target="_blank" rel="noopener noreferrer">
          <img src={MoshicamLogo} alt="Moshicam Logo" className="h-6" />
        </a>
      </div>
      <p><span style={{ color: secondaryColor }}>Day:</span> {themeInfo.dayNumber}</p>
      <p><span style={{ color: secondaryColor }}>Theme:</span> {themeInfo.themeName}</p>
      <p><span style={{ color: secondaryColor }}>Submitted by:</span> {themeInfo.submittedBy}</p>
      <p><span style={{ color: secondaryColor }}>Next theme in:</span> {countdown}</p>
    </div>
  );
};

export default DailyThemeInfo;