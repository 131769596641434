
import React, { useState, useEffect, useCallback, useRef } from "react";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { Navigate } from 'react-router-dom';
import io from "socket.io-client";
import Spinner from "../../components/ui/Spinner";
import CurrentGrid from "../../components/moshicam/CurrentGrid";
import SearchAndFilterControlsMoshicam from "../../components/moshicam/SearchAndFilterControlsMoshicam";
import NFTListMoshicam from "../../components/moshicam/NFTListMoshicam";
import useNFTData from "../../hooks/useNFTData";
import NFTDetails from "../../components/moshicam/NFTDetails";
import { AttributeValue, NFT } from "../../types/types";
import SearchBarMobile from "../../components/moshicam/SearchBarMobile"; // Import SearchBarMobile
import { useMobile } from "../../context/MobileContext";
import { log, showError } from '../../utils/logger';
import { Socket } from 'socket.io-client';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');

// Add this type definition at the top of your file
type UserChanges = {[key: number]: AttributeValue | null};

const Contribute: React.FC = () => {
  const { user, login, logout } = usePrivy();
  const { wallets } = useWallets();
  const [grid, setGrid] = useState<(AttributeValue | null)[]>(Array(9).fill(null));
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCell, setSelectedCell] = useState<number | null>(null);
  const [selectedNFTForDetails, setSelectedNFTForDetails] = useState<NFT | null>(null);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState<AttributeValue | null>(null);
  const [selectedNFT, setSelectedNFT] = useState<NFT | null>(null);
  const [gridName, setGridName] = useState<string>("Current Grid");
  const [userCell, setUserCell] = useState<number | null>(null);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [placedByAddresses, setPlacedByAddresses] = useState<{ [key: number]: string }>({});
  const [tooltipText, setTooltipText] = useState<string | null>(null);
  const [tooltipTimer, setTooltipTimer] = useState<NodeJS.Timeout | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const address = user?.wallet?.address;
  const gridNFTAddress = process.env.REACT_APP_THEGRID_CONTRACT_ADDRESS ?? "";

  const {
    nfts,
    sortedNFTs,
    isLoading,
    isError,
    error: nftError,
    searchQuery,
    setSearchQuery,
    toggleSortMethod,
    sortMethod,
    toggleGroupingMethod,
    groupingMethod,
    toggleSpamFilter,
    isSpamFiltered,
    isGridFilterEnabled,
    toggleGridFilter,
    refetchNFTs,
    isRefetching,
    refetchError,
    hasData,
    isInitialLoad,
  } = useNFTData({ address, gridNFTAddress });

  const refreshNFTList = useCallback(async () => {
    await refetchNFTs();
    setRefreshTrigger(prev => prev + 1);
    setShouldRefresh(false); // Reset the refresh trigger
  }, [refetchNFTs]);

  // Add this useEffect to trigger refresh on component mount
  useEffect(() => {
    // Trigger refresh when component mounts
    setShouldRefresh(true);
  }, []);

  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  const toggleSearchBar = () => {
    setIsSearchBarVisible(prev => !prev);
  };

  const { isMobile } = useMobile();

  // Update the state definition
  const [userChanges, setUserChanges] = useState<UserChanges>({});

  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND;
    if (typeof backendUrl === 'string') {
      socketRef.current = io(backendUrl);

      socketRef.current.on("connect", () => {
        log("Connected to WebSocket server");
      });

      socketRef.current.on("disconnect", () => {
        log("Disconnected from WebSocket server");
      });

      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
      };
    } else {
      showError("REACT_APP_BACKEND is not defined or not a string");
    }
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const fetchCurrentGrid = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/moshicam/getCurrentGrid`); // Updated URL
        if (!response.ok) {
          throw new Error("Failed to fetch the current grid");
        }
        const gridData = await response.json();

        setGridName(gridData.description || "Unnamed Grid");

        const filledGrid: (AttributeValue | null)[] = Array(9).fill(null);
        const placedBy: { [key: number]: string } = {};

        Object.entries(gridData.items).forEach(([index, item]: [string, any]) => {
          const gridIndex = parseInt(index) - 1;
          filledGrid[gridIndex] = {
            contract: item.contract,
            description: item.description || "",
            tokenId: item.tokenId,
            name: item.name,
            originalCreator: item.placedby || "",
            image: item.image || "",
            chainId: parseInt(item.chainId) || 1,
          };
          placedBy[gridIndex] = item.placedby || "";
        });

        setGrid(filledGrid);
        setPlacedByAddresses(placedBy);
      } catch (error) {
        showError("Error fetching current grid:", error);
        setError("Failed to load the current grid.");
        setGrid(Array(9).fill(null));
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentGrid();
  }, []); // This effect also runs once on mount

  useEffect(() => {
    if (!socketRef.current) return;

    const handleGridUpdate = (gridData: any) => {
      log("Received gridUpdate event:", gridData);
      const updatedGrid = Array(9).fill(null);
      const newPlacedBy: { [key: number]: string } = {};

      // Fill in the updated cells
      Object.entries(gridData.items).forEach(([index, item]: [string, any]) => {
        const gridIndex = parseInt(index) - 1;
        log(`Processing cell ${gridIndex}:`, item);
        
        updatedGrid[gridIndex] = {
          contract: item.contract,
          description: item.description || "",
          tokenId: item.tokenId,
          name: item.name,
          originalCreator: item.placedby || "",
          image: item.image || "",
          chainId: parseInt(item.chainId) || 1,
        };
        newPlacedBy[gridIndex] = item.placedby || "";
      });

      // Check for cleared cells
      grid.forEach((cell, index) => {
        if (cell && !updatedGrid[index]) {
          log(`Cell ${index} has been cleared`);
          clearCell(index);
        }
      });

      setGrid(updatedGrid);
      setPlacedByAddresses(newPlacedBy);

      log("Updated grid:", updatedGrid);
      log("Current placedByAddresses:", newPlacedBy);

      // Clear user selections if their cell was updated
      if (userCell !== null && updatedGrid[userCell] !== grid[userCell]) {
        log(`Clearing selections for user cell ${userCell}`);
        setUserCell(null);
        setSelectedCell(null);
        setSelectedAttributeValue(null);
        setSelectedNFT(null);
        setCanSave(false);
        setUserChanges(prev => {
          const newChanges = {...prev};
          delete newChanges[userCell];
          return newChanges;
        });
      }
    };

    socketRef.current.on("gridUpdate", handleGridUpdate);

    return () => {
      if (socketRef.current) {
        socketRef.current.off("gridUpdate", handleGridUpdate);
      }
    };
  }, [grid, userCell]); // This effect runs when grid or userCell changes

  const handleCellSelect = useCallback((index: number) => {
    if (userCell !== null && userCell !== index) {
      log(`Clearing previous selection at cell ${userCell}`);
      clearCell(userCell);
    }
    setSelectedCell(index);
  }, [userCell]); // Add any dependencies

  const handleNFTSelect = (nft: NFT) => {
    if (selectedCell !== null) {
      // Clear any previously selected cell
      if (userCell !== null && userCell !== selectedCell) {
        log(`Clearing previous selection at cell ${userCell}`);
        clearCell(userCell);
      }

      const selectedAttributeValue: AttributeValue = {
        contract: nft.contract,
        description: nft.description || "",
        tokenId: nft.tokenId,
        name: nft.name,
        originalCreator: nft.originalCreator || "",
        image: nft.imageUrl || "",
        chainId: nft.chainId,
      };

      setUserChanges(prev => {
        // Clear any previous changes
        const newChanges: UserChanges = {};
        newChanges[selectedCell] = selectedAttributeValue;
        return newChanges;
      });
      setSelectedAttributeValue(selectedAttributeValue);
      setSelectedNFT(nft);
      setUserCell(selectedCell);
      setCanSave(true);
    }
  };

  const handleSave = async () => {
    if (userCell === null || !selectedAttributeValue) {
      alert("Please select a cell and an NFT before saving.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/moshicam/submitPhoto`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address,
            photoData: selectedAttributeValue,
            position: userCell + 1,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const updatedItem = await response.json();
      const updatedGrid = [...grid];
      updatedGrid[userCell] = {
        contract: updatedItem.contract,
        description: updatedItem.description || "",
        tokenId: updatedItem.tokenId,
        name: updatedItem.name,
        originalCreator: updatedItem.placedby || "",
        image: updatedItem.image || "",
        chainId: parseInt(updatedItem.chainId) || 1,
      };
      setGrid(updatedGrid);
      
      // Clear all selections after successful save
      setSelectedCell(null);
      setSelectedAttributeValue(null);
      setSelectedNFT(null);
      setUserCell(null);
      setCanSave(false);
      setUserChanges({});
    } catch (error) {
      showError("Error submitting photo:", error);
      alert(`Failed to submit photo: ${(error as Error).message}`);
    }
  };

  // Add this state for grid view toggle
  const [isGridViewEnabled, setIsGridViewEnabled] = useState(true);

  const [searchBarMobileTop, setSearchBarMobileTop] = useState("45px");

  const showTooltip = (text: string) => {
    // Clear any existing timer
    if (tooltipTimer) {
      clearTimeout(tooltipTimer);
    }

    // Set the new tooltip text
    setTooltipText(text);

    // Set a new timer
    const timer = setTimeout(() => {
      setTooltipText(null);
    }, 5000);

    // Save the new timer
    setTooltipTimer(timer);
  };

  // Clean up the timer when the component unmounts
  useEffect(() => {
    return () => {
      if (tooltipTimer) {
        clearTimeout(tooltipTimer);
      }
    };
  }, [tooltipTimer]);



  // Update the clearCell function
  const clearCell = useCallback((index: number) => {
    log(`Clearing cell ${index}`);
    setGrid(prevGrid => {
      const newGrid = [...prevGrid];
      newGrid[index] = null;
      return newGrid;
    });
    setUserChanges(prev => {
      const newChanges = {...prev};
      delete newChanges[index];
      return newChanges;
    });
    setSelectedCell(null);
    setSelectedAttributeValue(null);
    setSelectedNFT(null);
    setUserCell(null);
    setCanSave(false);
  }, []); // Add any dependencies

  return (
    <div className="min-h-screen bg-gray-0 flex flex-col items-center justify-center">
      {isMobile && isSearchBarVisible && (
        <div style={{
          position: 'fixed',
          top: '366px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10000,
          width: '100%'
        }}>
          <SearchBarMobile
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            nftsLength={nfts.length}
            className="w-full"
          />
        </div>
      )}
      <main
        className="sm:ml-[128px] ml-[0px] fixed-card -ml-[35px]"
        style={{
          top: isMobile ? "45px" : "20px",
          left: isMobile ? "50%" : "180px",
          transform: isMobile ? "translateX(-50%)" : "translateX(0%)",
        }}
      >
        {loading ? (
          <Spinner />
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <CurrentGrid
            images={grid.map((item, index) => userChanges[index] || item)}
            gridName={gridName}
            onCellSelect={handleCellSelect}
            clearCell={clearCell}
            selectedCell={selectedCell}
            userCell={userCell}
            placedByAddresses={placedByAddresses}
            tooltipText={tooltipText}
          />
        )}
      </main>

      <div className="content-container mt-20 flex flex-col items-center">
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backdropFilter: isMobile ? "none" : "blur(10px)",
            background: isMobile ? "none" : "rgba(255, 255, 255, 0.1)",
            borderRadius: "1px",
            boxShadow: isMobile ? "none" : "0 15px 35px rgba(0, 0, 0, 0.5)",
            height: isMobile ? "250px" : "70px",
            justifyContent: "center",
            margin: "15px",
            overflow: "hidden",
            padding: isMobile ? "0px" : "0px",
            position: "fixed",
            top: isMobile ? "380px" : "21px",
            left: isMobile ? "0px" : "720px",
            transform: isMobile ? "translateX(-15px)" : "translateX(-17%)",
            width: isMobile ? "100%" : "350px",
            zIndex: 1,
          }}
          className="p-0 mb-10 w-[95%] mx-auto"
        >
          <SearchAndFilterControlsMoshicam
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            toggleSearchBar={toggleSearchBar}
            toggleSortMethod={toggleSortMethod}
            sortMethod={sortMethod}
            clearSearchQuery={() => setSearchQuery("")}
            nftsLength={nfts.length}
            onSave={handleSave}
            canSave={canSave}
            refreshNFTList={refreshNFTList}
            groupingMethod={groupingMethod}
            isGridFilterEnabled={isGridFilterEnabled}
            isSpamFiltered={isSpamFiltered}
            toggleGridFilter={toggleGridFilter}
            toggleSpamFilter={toggleSpamFilter}
            toggleGroupingMethod={toggleGroupingMethod}
            selectedCell={selectedCell}
            grid={grid}
            selectedNFT={selectedAttributeValue}
            userAddress={address || ""}
            setTooltipText={showTooltip}
            isMobile={isMobile}
            isRefetching={isRefetching}
            isInitialLoad={isInitialLoad}
            shouldRefresh={shouldRefresh}
          />
        </main>
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backdropFilter: "blur(10px)",
            background: "rgba(255, 255, 255, 0.1)",
            borderRadius: "1px",
            boxShadow: "0 15px 35px rgba(0, 0, 0, 0.5)",
            height: isMobile ? "calc(100vh - 5px)" : "calc(100vh - 120px)",
            justifyContent: "center",
            margin: "15px",
            overflow: "auto",
            padding: "0px",
            position: "fixed",
            top: isMobile ? "55px" : "90px",
            left: isMobile ? "50%" : "720px",
            transform: isMobile ? "translateX(-56%)" : "translateX(-17%)",
            width: isMobile ? "250px" : "350px",
            zIndex: 2,
          }}
          className="p-0 max-w-[600px] mb-10 w-[95%] mx-auto"
        >
          <NFTListMoshicam
            searchQuery={searchQuery}
            refreshNFTList={refreshNFTList}
            nfts={sortedNFTs}
            selectedNFT={selectedNFT}
            toggleSelectNFT={handleNFTSelect}
            handleImageError={(e, nft) => {
              // Implement your image error handling logic here
              showError("Image failed to load", nft);
            }}
            selectedCell={selectedCell}
            key={`nft-list-${isMobile}`} // Force re-render when isMobile changes
            sortMethod={sortMethod}
            toggleSortMethod={toggleSortMethod}
            isGridViewEnabled={isGridViewEnabled}
            isMobile={isMobile}
            isRefetching={isRefetching}
            refreshTrigger={refreshTrigger}
          />
          {isLoading && <p>Loading NFTs...</p>}
          {isError && (
            <p className="text-red-500">
              Error finding photos: {nftError instanceof Error ? nftError.message : 'Unknown error'}
            </p>
          )}
          {refetchError && (
            <p className="text-red-500">
              Error refreshing photo collection: {refetchError.message}
            </p>
          )}
        </main>
      </div>

      {selectedNFTForDetails && (
        <NFTDetails
          nft={{
            contract: selectedNFTForDetails.contract,
            tokenId: selectedNFTForDetails.tokenId,
            name: selectedNFTForDetails.name,
            originalCreator: selectedNFTForDetails.originalCreator,
            image: selectedNFTForDetails.imageUrl || "",
            chainId: selectedNFTForDetails.chainId,
          }}
          onClose={() => setSelectedNFTForDetails(null)}
        />
      )}
    </div>
  );
};

export default Contribute;