import React, { useEffect, useState, useCallback } from 'react';
import { useColor } from '../../context/ColorContext';
import { useTheme } from '../../context/ThemeContext';
import UseBaseColors from '../UseBaseColors';
import { usePrivy } from "@privy-io/react-auth";
import refreshIcon from '../../icons/refresh.svg';
import { IoMdColorPalette, IoMdRefresh } from "react-icons/io";
import { log, showError } from "../../utils/logger";


interface ColorPickerProps {
  onColorSelect: (colors: string[]) => void;
  selectedColors: string[];
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onColorSelect, selectedColors }) => {
  const [primaryColor, setPrimaryColor] = useState<string>(selectedColors[0] || '');
  const [secondaryColor, setSecondaryColor] = useState<string>(selectedColors[1] || '');
  const { appBaseColors, userBaseColors, fetchColors } = useColor();
  const { primaryColor: themePrimaryColor, secondaryColor: themeSecondaryColor } = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchInitialColors = async () => {
      setIsLoading(true);
      await fetchColors(false);
      setIsLoading(false);
    };
    fetchInitialColors();
  }, [fetchColors]);

  const handleRefresh = async () => {
    setIsLoading(true);
    await fetchColors(true);
    setIsLoading(false);
  };

  const handleColorSelect = useCallback(
    (colors: string[]) => {
      if (colors.length > 0) {
        const newPrimaryColor = colors[0];
        log('New primary color selected:', newPrimaryColor);

        // Shift the old primary color to secondary
        setSecondaryColor(primaryColor);
        setPrimaryColor(newPrimaryColor);

        // Call onColorSelect with the updated colors
        onColorSelect([newPrimaryColor, primaryColor]);
      }
    },
    [onColorSelect, primaryColor]
  );

  useEffect(() => {
    // Update local state when selectedColors prop changes
    if (selectedColors.length >= 2) {
      setPrimaryColor(selectedColors[0]);
      setSecondaryColor(selectedColors[1]);
    }
  }, [selectedColors]);

  return (
    <div className="bg-[rgba(255,255,255,0.0)] p-2 z-[9999] overflow-y-auto scrollbar-hide h-[250px] md:h-[240px] w-[160px] md:w-[100%]">
      <div className="flex items-center mb-2">
        <span className="font-tiny5 uppercase z-[9999]">
          <span className="md:hidden">Theme:</span>
          <span className="hidden md:inline">Theme Colors:</span>
        </span>
        <a
          href="https://www.basecolors.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 text-current hover:text-primary-500 transition-colors relative z-[10000]"
          aria-label="Buy more Base Colors"
          title="Buy More Base Colors"
        >
          <IoMdColorPalette size={18} />
        </a>
        <button
          onClick={handleRefresh}
          className="ml-2 focus:outline-none group"
          aria-label="Refresh Base Colors"
          title="Refresh your collection"
        >
          <IoMdRefresh size={18} />
        </button>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <UseBaseColors
          onColorSelect={handleColorSelect}
          selectedColors={[primaryColor, secondaryColor]}
          appBaseColors={appBaseColors}
          userBaseColors={userBaseColors}
        />
      )}

    </div>
  );
};

export default ColorPicker;