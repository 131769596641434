// /hooks/useNFTData.ts

import { useState, useEffect, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query'; // Updated import
import axios from 'axios';
import { fetchNFTs } from '../api/fetchNfts';
import { NFT, UseNFTDataProps } from "../types/types"; // Import interfaces from types.ts
import { log, showError } from '../utils/logger';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// showError('This is an error message');
const useNFTData = ({ address, gridNFTAddress }: UseNFTDataProps) => {
  const queryClient = useQueryClient();

  const [nfts, setNfts] = useState<NFT[]>([]);
  const [sortedNFTs, setSortedNFTs] = useState<NFT[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortMethod, setSortMethod] = useState<'description' | 'recent' | 'oldest' | 'borderId'>('recent');
  const [searchQuery, setSearchQuery] = useState('');
  const [groupingMethod, setGroupingMethod] = useState<'individual' | 'collection'>('individual');
  const [isSpamFiltered, setIsSpamFiltered] = useState<'off' | 'on' | 'only'>('on');
  const [spamWords, setSpamWords] = useState<string[]>([]);
  const [isGridFilterEnabled, setIsGridFilterEnabled] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [refetchError, setRefetchError] = useState<Error | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { data: fetchedNFTs, isLoading, isError, error, refetch } = useQuery(
    ['nfts', address, gridNFTAddress],
    () => fetchNFTs(address!),
    {
      enabled: !!address && !!gridNFTAddress,
    }
  );

  useEffect(() => {
    if (fetchedNFTs !== undefined) {
      const allNFTs = Object.values(fetchedNFTs).flat().map(nft => ({
        ...nft,
        borderId: nft.borderId || 'default' // Ensure borderId is captured, use 'default' if not present
      }));
      setNfts(allNFTs);
      setIsInitialLoad(false);
      log(`NFTs set. Count: ${allNFTs.length}`);
    }
  }, [fetchedNFTs]);

  useEffect(() => {
    const fetchSpamWords = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/getSpamWords`); // Updated to use environment variable
        setSpamWords(response.data);
      } catch (error) {
        showError('Failed to fetch spam words:', error);
      }
    };

    fetchSpamWords();
  }, []);

  useEffect(() => {
    sortAndFilterNFTs();
  }, [nfts, sortOrder, sortMethod, searchQuery, groupingMethod, isSpamFiltered, spamWords, isGridFilterEnabled]);

  const toggleGridFilter = () => {
    setIsGridFilterEnabled((prev) => !prev);
  };

  const isSpamWord = (nft: NFT) => {
    return spamWords.some((word) => nft.name.toLowerCase().includes(word));
  };

  const sortAndFilterNFTs = () => {
    let filtered = nfts.filter(
      (nft) =>
        nft.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        nft.tokenId.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        nft.contract.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (nft.description && nft.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (nft.labels && nft.labels.some(label => label.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    if (isSpamFiltered === 'on') {
      filtered = filtered.filter((nft) => !isSpamWord(nft));
    } else if (isSpamFiltered === 'only') {
      filtered = filtered.filter((nft) => isSpamWord(nft));
    }

    if (isGridFilterEnabled) {
      filtered = filtered.filter((nft) => nft.contract === gridNFTAddress);
    }

    const sortFunction = (a: NFT, b: NFT) => {
      if (sortMethod === 'description') {
        const descA = a.description || '';
        const descB = b.description || '';
        
        const isSpecialDescription = (desc: string) => {
          const trimmedDesc = desc.trim();
          return !desc || 
                 trimmedDesc === '[moshi.cam](moshi.cam)' || 
                 trimmedDesc === 'This is a description.';
        };
        
        if (isSpecialDescription(descA) && isSpecialDescription(descB)) return 0;
        if (isSpecialDescription(descA)) return 1;
        if (isSpecialDescription(descB)) return -1;
        
        return sortOrder === 'asc'
          ? descA.localeCompare(descB)
          : descB.localeCompare(descA);
      } else if (sortMethod === 'recent' || sortMethod === 'oldest') {
        const dateA = new Date(a.acquiredAt || 0).getTime();
        const dateB = new Date(b.acquiredAt || 0).getTime();
        return sortOrder === 'asc'
          ? (sortMethod === 'recent' ? dateB - dateA : dateA - dateB)
          : (sortMethod === 'recent' ? dateA - dateB : dateB - dateA);
      } else if (sortMethod === 'borderId') {
        const borderA = a.metadata?.properties?.borderId || 'default';
        const borderB = b.metadata?.properties?.borderId || 'default';
        return sortOrder === 'asc'
          ? borderA.localeCompare(borderB)
          : borderB.localeCompare(borderA);
      }
      return 0;
    };

    if (groupingMethod === 'collection') {
      const groupedByCollection = filtered.reduce((acc, nft) => {
        const collection = nft.contract;
        if (!acc[collection]) {
          acc[collection] = [];
        }
        acc[collection].push(nft);
        return acc;
      }, {} as { [key: string]: NFT[] });

      // Sort NFTs within each collection
      Object.values(groupedByCollection).forEach(group => {
        group.sort((a, b) => {
          if (sortMethod === 'recent' || sortMethod === 'oldest') {
            // First sort by date
            const dateA = new Date(a.acquiredAt || 0).getTime();
            const dateB = new Date(b.acquiredAt || 0).getTime();
            const dateComparison = sortOrder === 'asc'
              ? (sortMethod === 'recent' ? dateB - dateA : dateA - dateB)
              : (sortMethod === 'recent' ? dateA - dateB : dateB - dateA);
            
            // If dates are the same, sort by tokenId
            if (dateComparison === 0) {
              return parseInt(a.tokenId) - parseInt(b.tokenId);
            }
            return dateComparison;
          }
          return sortFunction(a, b);
        });
      });

      // Flatten the grouped and sorted NFTs
      setSortedNFTs(Object.values(groupedByCollection).flat());
    } else {
      setSortedNFTs(filtered.sort(sortFunction));
    }
  };

  const toggleSortMethod = () => {
    setSortMethod((prev) => {
      if (prev === 'description') return 'recent';
      if (prev === 'recent') return 'oldest';
      if (prev === 'oldest') return 'borderId';
      return 'description';
    });
  };

  const toggleGroupingMethod = () => {
    setGroupingMethod((prev) => (prev === 'individual' ? 'collection' : 'individual'));
  };

  const toggleSpamFilter = () => {
    setIsSpamFiltered((prev) => {
      if (prev === 'off') return 'on';
      if (prev === 'on') return 'only';
      return 'off';
    });
  };

  const refetchNFTs = useCallback(async () => {
    if (!address) return;

    setIsRefetching(true);
    setRefetchError(null);
    try {
      await refetch();
      // The fetchedNFTs will be updated automatically by react-query
      // and the useEffect hook will handle updating nfts and sortedNFTs
    } catch (error) {
      setRefetchError(error as Error);
      showError('Error refreshing photos:', error);
    } finally {
      setIsRefetching(false);
    }
  }, [address, refetch]);

  // Function to update sortedNFTs based on current filters and sorting
  const updateSortedNFTs = useCallback((nftsToSort: NFT[]) => {
    let filtered = nftsToSort;
    // Apply your filtering logic here
    // ...

    // Apply your sorting logic here
    // ...

    setSortedNFTs(filtered);
  }, [/* dependencies for your filtering and sorting logic */]);

  const hasData = nfts.length > 0;

  return {
    nfts,
    sortedNFTs,
    isLoading,
    isError,
    error,
    searchQuery,
    setSearchQuery,
    toggleSortMethod,
    sortMethod,
    toggleGroupingMethod,
    groupingMethod,
    toggleSpamFilter,
    isSpamFiltered,
    isGridFilterEnabled,
    toggleGridFilter,
    spamWords, // Return spamWords for use in Create.tsx
    refetchNFTs,
    isRefetching,
    refetchError,
    hasData,
    isInitialLoad,
  };
};

export default useNFTData;