import React from "react";
import ReactDOM from "react-dom";
import GradientButton from "../ui/GradientButton";
import { ConfirmationModalProps } from "../../types/types";

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9000]">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full mx-4 relative top-1/5 sm:top-[-220px] lg:top-[-220px] lg:left-[75px]">
        <h2 className="text-2xl font-bold mb-4 text-white">Confirm Action</h2>
        <p className="mb-6 text-white">{message}</p>
        <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
          <GradientButton
            onClick={onClose}
            className="w-full sm:w-auto !bg-transparent hover:!bg-gray-700 !px-4"
          >
            Cancel
          </GradientButton>
          <GradientButton
            className="w-full sm:w-auto !bg-gray-600 hover:!bg-gray-700 !px-4"
            onClick={onConfirm}
          >
            Confirm
          </GradientButton>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmationModal;