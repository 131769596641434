import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext'; // Add this import

interface SidebarNavLinksProps {
  onLinkClick?: () => void;
}

const SidebarNavLinks: React.FC<SidebarNavLinksProps> = React.memo(({ onLinkClick }) => {
  const { secondaryColor, textColor } = useTheme(); // Use the useTheme hook

  const linkStyle = {
    color: textColor,
    transition: 'color 0.3s ease',
  };

  const linkClass = `block uppercase font-['Jersey_15'] text-2xl leading-none`;

  const handleTouchStart = (e: React.TouchEvent<HTMLAnchorElement>) => {
    e.currentTarget.style.color = secondaryColor;
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLAnchorElement>) => {
    e.currentTarget.style.color = textColor;
  };

  return (
    <div className="flex flex-col space-y-[.25rem] text-right sm:text-left">
      <Link
        to="/moshicam"
        className={`${linkClass}`} // Add vertical padding for larger touch area
        onClick={onLinkClick}
        style={linkStyle}
        onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
        onMouseLeave={(e) => e.currentTarget.style.color = textColor}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        Today's Grid
      </Link>
      <Link
        to="/moshicam/feed"
        className={linkClass}
        onClick={onLinkClick}
        style={linkStyle}
        onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
        onMouseLeave={(e) => e.currentTarget.style.color = textColor}
      >
        All Grids
      </Link>
      <Link
        to="/moshicam/day/32"
        className={linkClass}
        onClick={onLinkClick}
        style={linkStyle}
        onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
        onMouseLeave={(e) => e.currentTarget.style.color = textColor}
      >
        FC Fridays
      </Link>
      <Link
        to="/moshicam/submit-theme"
        className={linkClass}
        onClick={onLinkClick}
        style={linkStyle}
        onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
        onMouseLeave={(e) => e.currentTarget.style.color = textColor}
      >
        Submit Theme
      </Link>
    </div>
  );
});

export default SidebarNavLinks;