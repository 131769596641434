import React, { useState, useRef, useEffect } from "react";
import ImageModal from "./ImageModalMoshicam";
import { useEnsLookup } from '../../hooks/useEnsLookup';
import { useGridView } from "../../context/GridViewContext";
import { useTheme } from '../../context/ThemeContext'; // Add this import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faCopy } from '@fortawesome/free-solid-svg-icons'; // Add faCopy
import moshicamLogo from "../../icons/moshicam-logo.png";
import farcasterLogo from '../../icons/farcaster-transparent-purple.png';
import lensLogo from '../../icons/lens-logo.svg';
import ensLogo from '../../icons/ens-logo.svg';
import { log, showError } from '../../utils/logger';


interface GridData {
  _id: string;
  description: string;
  gridColumns: number;
  name: string;
  items: {
    [key: string]: {
      contract: string;
      description: string;
      tokenId: number | string;
      name: string;
      originalCreator: string;
      image: string;
      chainId: number;
      placedby: string;
    }
  };
}

interface ThemeData {
  _id: string;
  themeName: string;
  submittedBy: string;
}

interface SingleGridMoshicamProps {
  gridData: GridData;
  showShadows?: boolean;
}

const SingleGridMoshicam: React.FC<SingleGridMoshicamProps> = ({ gridData, showShadows = true }) => {
  const { textColor, secondaryColor } = useTheme(); // Use the useTheme hook
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const [displayText, setDisplayText] = useState<string | JSX.Element>(gridData.name);
  const [isScrolling, setIsScrolling] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const [textDisplayMode, setTextDisplayMode] = useState<'description' | 'moshicamlink' | 'placedby'>('description');
  const [nonModalTextDisplayMode, setNonModalTextDisplayMode] = useState<'theme' | 'themeSubmittedBy' | 'shareLink'>('theme');
  const [copied, setCopied] = useState(false); // Add state for copied message
  const [themes, setThemes] = useState<ThemeData[]>([]); // Add state for themes
  const { isGridViewEnabled } = useGridView();

  // Extract dayNumber and gridNumber from gridData._id
  const [dayNumber, gridNumber] = gridData._id.split(':').map(Number);

  useEffect(() => {
    log(`Day Number: ${dayNumber}, Grid Number: ${gridNumber}`);
  }, [dayNumber, gridNumber]);

  useEffect(() => {
    // Fetch themes from the server
    const fetchThemes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/moshicam/allThemes`);
        const data = await response.json();
        setThemes(data);
      } catch (error) {
        console.error("Failed to fetch themes: ", error);
      }
    };

    fetchThemes();
  }, []);

  const images = Array(9).fill(null).map((_, index) => gridData.items[index + 1] || null);
  const placedByAddresses = Object.fromEntries(
    Object.entries(gridData.items).map(([key, value]) => [parseInt(key) - 1, value.placedby])
  );

  const { ensName = "", resolvedAddress = "", farcasterUsernames = [], lensUsernames = [] } = useEnsLookup(modalIndex !== null ? placedByAddresses[modalIndex] : undefined);

  const handleCellClick = (index: number) => {
    if (images[index] !== null) {
      setModalIndex(index);
    }
  };

  const closeModal = () => {
    setModalIndex(null);
    setDisplayText(gridData.name);
  };

  const navigateNext = () => {
    if (modalIndex !== null) {
      let nextIndex = (modalIndex + 1) % images.length;
      while (images[nextIndex] === null) {
        nextIndex = (nextIndex + 1) % images.length;
      }
      setModalIndex(nextIndex);
    }
  };

  const navigatePrevious = () => {
    if (modalIndex !== null) {
      let prevIndex = (modalIndex - 1 + images.length) % images.length;
      while (images[prevIndex] === null) {
        prevIndex = (prevIndex - 1 + images.length) % images.length;
      }
      setModalIndex(prevIndex);
    }
  };

  const generateMoshicamLink = (contractAddress: string, tokenId: string) => {
    return `https://moshi.cam/pics/${contractAddress}/${tokenId}`;
  };

  const handleCopyClick = async (shareLink: string) => {
    try {
      await navigator.clipboard.writeText(shareLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  useEffect(() => {
    if (modalIndex !== null && images[modalIndex]) {
      const description = images[modalIndex]?.description || 'Moshi';
      switch (textDisplayMode) {
        case 'description':
          setDisplayText(description);
          setIsScrolling(description.length > 25);
          break;
        case 'moshicamlink':
          setDisplayText("Open with: ");
          setIsScrolling(false);
          break;
        case 'placedby':
          const profiles = [
            ensName ? (
              <span key="ens" className="inline-flex items-center">
                <img src={ensLogo} alt="ENS" className="w-4 h-4 mr-1" />
                {ensName}
              </span>
            ) : null,
            ...(farcasterUsernames || []).map(name => (
              <a
                key={`fc:${name}`}
                href={`https://warpcast.com/${name}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center"
              >
                <img src={farcasterLogo} alt="Farcaster" className="w-4 h-4 mr-1" />
                {name}
              </a>
            )),
            ...(lensUsernames || []).map(name => (
              <a
                key={`lens:${name}`}
                href={`https://hey.xyz/u/${name}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center"
              >
                <img src={lensLogo} alt="Lens" className="w-4 h-4 mr-1" />
                lens:{name}
              </a>
            ))
          ].filter((profile): profile is JSX.Element => profile !== null);
          
          const placedByText = profiles.length > 0
            ? <span className="inline-flex items-center">
                <span className="mr-2">Added By:</span>
                {profiles.reduce((prev, curr, index) => (
                  <React.Fragment key={index}>
                    {prev}
                    {index > 0 && <span className="mx-1">/</span>}
                    {curr}
                  </React.Fragment>
                ))}
              </span>
            : `Added By: ${resolvedAddress || placedByAddresses[modalIndex] || "Unknown"}`;

          setDisplayText(placedByText);

          // Calculate the total length of the placedByText
          const placedByTextLength = profiles.reduce((acc, profile) => {
            const textContent = profile.props.children.reduce((textAcc: string, child: any) => 
              textAcc + (typeof child === 'string' ? child : child.props.children), '');
            return acc + textContent.length;
          }, "Added By: ".length + (resolvedAddress || placedByAddresses[modalIndex] || "Unknown").length);

          setIsScrolling(placedByTextLength >= 20);
          break;
      }
    } else {
      switch (nonModalTextDisplayMode) {
        case 'theme':
          setDisplayText(gridData.name);
          setIsScrolling(gridData.name.length > 25);
          break;
        case 'themeSubmittedBy':
          const theme = themes.find(t => t._id === dayNumber.toString());
          const submittedByText = `Theme submitted by: ${theme?.submittedBy || "Unknown"}`;
          setDisplayText(submittedByText);
          setIsScrolling(submittedByText.length > 23); // Update scrolling condition

          break;
        case 'shareLink':
          const shareLink = `https://www.thegrid.pics/moshicam/day/${dayNumber}/${gridNumber}`;
          setDisplayText(
            <div className="flex items-center justify-center">Share Link to Grid:
              <a 
                href={shareLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-500 underline"
                style={{ color: textColor }}
                onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
                onMouseLeave={(e) => e.currentTarget.style.color = textColor}
              >
                <FontAwesomeIcon icon={faShareSquare} className="h-4 ml-2 inline-block" />
              </a>
              <button
                onClick={() => handleCopyClick(shareLink)}
                className="ml-2 text-blue-500 underline"
                style={{ color: textColor }}
                onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
                onMouseLeave={(e) => e.currentTarget.style.color = textColor}
              >
                <FontAwesomeIcon icon={faCopy} className="h-4 inline-block" />
              </button>
              {copied && <span className="ml-2">Copied</span>}
            </div>
          );
          setIsScrolling(false);
          break;
      }
    }
  }, [modalIndex, textDisplayMode, nonModalTextDisplayMode, ensName, resolvedAddress, farcasterUsernames, lensUsernames, dayNumber, gridNumber, gridData.name, gridData.description, textColor, secondaryColor, copied, themes]);

  useEffect(() => {
    if (isScrolling && textRef.current) {
      const textWidth = textRef.current.scrollWidth;
      const containerWidth = textRef.current.offsetWidth;
      const animationDuration = (textWidth / 50) + 's';
      textRef.current.style.animation = `scroll-left ${animationDuration} linear infinite`;
    } else if (textRef.current) {
      textRef.current.style.animation = 'none';
    }
  }, [isScrolling, displayText]);

  const handleTextClick = () => {
    if (modalIndex !== null) {
      setTextDisplayMode(prevMode => {
        switch (prevMode) {
          case 'description': return 'moshicamlink';
          case 'moshicamlink': return 'placedby';
          case 'placedby': return 'description';
        }
      });
    } else {
      setNonModalTextDisplayMode(prevMode => {
        switch (prevMode) {
          case 'theme': return 'themeSubmittedBy';
          case 'themeSubmittedBy': return 'shareLink';
          case 'shareLink': return 'theme';
        }
      });
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className={`w-[350px] h-[420px] bg-white bg-opacity-5 backdrop-blur-md ${
        isGridViewEnabled && showShadows ? 'shadow-lg shadow-black/30' : ''
      } flex items-center justify-center overflow-hidden relative z-10 ${
        isGridViewEnabled ? 'border border-white/10' : ''
      }`}>
        <div className={`flex justify-center w-full h-full ${
          isGridViewEnabled ? 'p-3' : ''
        } ${isGridViewEnabled && showShadows ? 'shadow-[0_15px_35px_rgba(0,0,0,0.5)]' : ''}`}>
          {/* Hide the grid when modalIndex is not null and !isGridViewEnabled */}
          {(modalIndex === null || isGridViewEnabled) && (
            <div className={`grid grid-cols-3 gap-x-[3px] gap-y-[3px] h-full w-full ${
              isGridViewEnabled ? 'p-2 shadow-inner' : ''
            } ${modalIndex !== null ? '' : isGridViewEnabled ? 'bg-black bg-opacity-20' : ''}`}>
              {images.map((item, index) => (
                <div
                  key={index}
                  className={`relative group cursor-pointer overflow-hidden w-full h-full ${
                    item ? "bg-green-500 bg-opacity-20" : "bg-white bg-opacity-10 hover:bg-opacity-50"
                  } transition-all duration-300 ease-in-out`}
                  onClick={() => handleCellClick(index)}
                  style={{ aspectRatio: '1 / 1' }}
                >
                  {item ? (
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-full h-full object-cover transition-transform duration-300 ease-in-out scale-[1.02]"
                      style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <div className="w-full h-full bg-white bg-opacity-10"></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {modalIndex !== null && (
          <div className="absolute inset-0 bg-black bg-opacity-0 flex items-center justify-center z-20">
            <div className="w-full h-full flex items-center justify-center">
              <ImageModal
                images={images.map(image => image ? {
                  contract: image.contract,
                  image: image.image,
                  name: image.name,
                  tokenId: image.tokenId.toString()
                } : null)}
                currentIndex={modalIndex}
                onClose={closeModal}
                onNavigateNext={navigateNext}
                onNavigatePrevious={navigatePrevious}
              />
            </div>
          </div>
        )}
      </div>
      {isGridViewEnabled && (
        <div className={`w-[318px] pt-2 bg-white bg-opacity-10 backdrop-blur-md ${showShadows ? 'shadow-lg shadow-black/50' : ''} mt-0 text-center text-white transition-all duration-300 ease-in-out overflow-hidden`}>
          <div 
            ref={textRef}
            className="text-xl font-bold mb-2 whitespace-nowrap font-tiny5 inline-block cursor-pointer flex items-center justify-center"
            onClick={handleTextClick}
          >
            {displayText}
            {textDisplayMode === 'moshicamlink' && modalIndex !== null && images[modalIndex] && (
              <a
                href={generateMoshicamLink(
                  images[modalIndex]?.contract || '',
                  images[modalIndex]?.tokenId.toString() || ''
                )}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <img 
                  src={moshicamLogo} 
                  alt="Moshicam Logo" 
                  className="h-6 ml-2 inline-block"
                />
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleGridMoshicam;