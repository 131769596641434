import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link
import FeedWelcome from './moshicam/feedWelcome';
import { log, showError } from '../utils/logger';
import GradientButton from '../components/ui/GradientButton';


const WelcomePage: React.FC = () => {
  const [currentDayNumber, setCurrentDayNumber] = useState<string>("1");

  useEffect(() => {
    const fetchCurrentDay = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/moshicam/getDayNumber`
        );
        const { dayNumber } = response.data;
        setCurrentDayNumber(dayNumber.toString());
      } catch (error) {
        showError("Error fetching current day:", error);
        setCurrentDayNumber("1");
      }
    };

    fetchCurrentDay();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="font-bold text-4xl mb-4 uppercase font-tiny5 text-white">
        The Grid
      </h1>
      <div className="-mt-[40px] sm:mt-0">
        <FeedWelcome dayNumber={currentDayNumber} />
      </div>
      <Link to="/help">
        <GradientButton className="!px-8 !py-3">
        Build The Grid Together
        </GradientButton>
      </Link>
    </div>
  );
};

export default WelcomePage;
