import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import SingleGridMoshicam from '../../components/moshicam/SingleGridMoshicam';
import { useGridView } from '../../context/GridViewContext';
import { log, showError } from '../../utils/logger';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');

interface GridData {
  _id: string;
  description: string;
  gridColumns: number;
  name: string;
  items: {
    [key: string]: {
      contract: string;
      description: string;
      tokenId: number | string;
      name: string;
      originalCreator: string;
      image: string;
      chainId: number;
      placedby: string;
    }
  };
  imageUrl: string | null;
  imageUrlOriginal: string | null;
  tokenURI: string | null;
}

interface FeedMoshicamProps {
  dayNumber: string;
}

const FeedWelcome: React.FC<FeedMoshicamProps> = ({ dayNumber }) => {
  const [grid, setGrid] = useState<GridData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isGridViewEnabled } = useGridView();
  const [isCurrentDay, setIsCurrentDay] = useState(false);

  useEffect(() => {
    const fetchDayInfo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/moshicam/getDayNumber`);
        const { dayNumber: currentDayNumber, dayDate } = response.data;
        
        log('API dayNumber:', currentDayNumber);
        log('Component dayNumber:', dayNumber);
        log('API dayDate:', dayDate);

        setIsCurrentDay(currentDayNumber.toString() === dayNumber);
      } catch (error) {
        showError('Error fetching day info:', error);
      }
    };

    fetchDayInfo();
  }, [dayNumber]);

  useEffect(() => {
    log('useEffect called, dayNumber:', dayNumber, 'isCurrentDay:', isCurrentDay);

    const fetchGrid = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/moshicam/previousgrid`);
        setGrid(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch grid');
        setLoading(false);
      }
    };

    fetchGrid();

    if (isCurrentDay) {
      log('Attempting to connect to WebSocket');
      const backendUrl = process.env.REACT_APP_BACKEND;
      if (typeof backendUrl === 'string') {
        const socket = io(backendUrl);

        socket.on('connect', () => {
          log('Connected to WebSocket server');
        });

        socket.on('disconnect', () => {
          log('Disconnected from WebSocket server');
        });

        socket.on('gridUpdate', (gridData: GridData) => {
          log('Received gridUpdate event', gridData);
          setGrid(gridData);
        });

        socket.on('connect_error', (error) => {
          showError('WebSocket connection error:', error);
        });

        return () => {
          log('Disconnecting from WebSocket');
          socket.disconnect();
        };
      } else {
        showError("REACT_APP_BACKEND is not defined or not a string");
      }
    }
  }, [dayNumber, isCurrentDay]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="feed-moshicam mt-[40px] sm:mt-0">
      {grid && (
        <div 
          key={grid._id}
          className={`grid-container ${isGridViewEnabled ? 'mb-10' : 'mb-0'}`}
        >
          <SingleGridMoshicam gridData={grid} />
        </div>
      )}
    </div>
  );
};

export default FeedWelcome;