// src/hooks/useEnsLookup.ts

import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { cache } from '../utils/cache';
import { debounce, throttle } from '../utils/throttle';
import { getAddress } from 'ethers'; // Import getAddress from ethers v6
import { log, showError } from '../utils/logger';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');

const isAddress = (address: string): boolean => {
  try {
    getAddress(address); // Use getAddress for validation
    return true;
  } catch {
    return false;
  }
};

export const useEnsLookup = (address: string | undefined) => {
  const [ensName, setEnsName] = useState<string | null>(null);
  const [resolvedAddress, setResolvedAddress] = useState<string>(address || '');
  const [farcasterUsernames, setFarcasterUsernames] = useState<string[]>([]);
  const [lensUsernames, setLensUsernames] = useState<string[]>([]);

  const fetchENS = useCallback(async () => {
    if (!address || !isAddress(address)) {
      setResolvedAddress('Invalid address');
      log(`Fetch aborted: Invalid address`);
      return;
    }

    const cachedResult = cache.get(address);
    if (cachedResult) {
      setEnsName(cachedResult.ensName);
      setResolvedAddress(cachedResult.resolvedAddress);
      setFarcasterUsernames(cachedResult.farcasterUsernames || []);
      setLensUsernames(cachedResult.lensUsernames || []);
      log(`Cache hit: ${address} -> ${cachedResult}`);
      return;
    }

    log(`Cache miss: ${address}, making API call...`);

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/fetchENS/${address}`); // Updated URL
      const { ensName, resolvedAddress, socials } = response.data;

      // Process socials to extract Farcaster and Lens usernames
      const farcasterUsernames = socials
        ? socials
            .filter((social: any) => social.dappName === 'farcaster')
            .map((social: any) => social.profileName)
        : [];

      const lensUsernames = socials
        ? socials
            .filter((social: any) => social.dappName === 'lens')
            .map((social: any) => social.profileName.replace('lens/', ''))
        : [];

      setEnsName(ensName);
      setResolvedAddress(resolvedAddress);
      setFarcasterUsernames(farcasterUsernames);
      setLensUsernames(lensUsernames);

      const result = { ensName, resolvedAddress, farcasterUsernames, lensUsernames };
      cache.set(address, result);
      log(`API Call: ${address} -> ${result}`);
    } catch (error) {
      showError('Error fetching ENS:', error);
      setResolvedAddress('Error resolving ENS name');
    }
  }, [address]);

  useEffect(() => {
    if (address) {
      const debouncedFetchENS = debounce(fetchENS, 300);
      const throttledFetchENS = throttle(debouncedFetchENS, 1000);
      throttledFetchENS();
    }
  }, [fetchENS, address]);

  return { ensName, resolvedAddress, farcasterUsernames, lensUsernames };
};
