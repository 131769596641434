import React, { useMemo } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { useMobile } from "./context/MobileContext";
import "./App.css";

import LoadingScreen from "./pages/LoadingScreen";
import AuthScreen from "./pages/AuthScreen";
import AppRoutes from "./routes/AppRoutes";

const App: React.FC = () => {
  const { ready, authenticated } = usePrivy();
  const { isMobile } = useMobile();

  const appClassNames = useMemo(() => {
    return `App flex ${isMobile ? 'flex-col' : 'flex-row'}`;
  }, [isMobile]);

  const mainContentClassNames = useMemo(() => {
    return `flex-grow p-4 text-white main-content ${isMobile ? 'mt-10' : 'ml-64'}`;
  }, [isMobile]);

  if (!ready) {
    return <LoadingScreen />;
  }

  return (
    <AppRoutes />
  );
};

export default App;