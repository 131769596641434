import React from 'react';
import { useGridView } from '../../context/GridViewContext';
import { ReactComponent as GridModeOn } from '../../icons/GridModeOn.svg';
import { ReactComponent as GridModeOff } from '../../icons/GridModeOff.svg';
import { Tooltip } from 'react-tooltip';

const GridMode: React.FC = () => {
  const { isGridViewEnabled, toggleGridView } = useGridView();

  return (
    <div className="flex justify-center w-1/3">
      {isGridViewEnabled ? (
        <GridModeOff
          className="cursor-pointer"
          style={{ width: '64px', height: '64px', opacity: 0.5 }}
          onClick={toggleGridView}
          data-tooltip-id="grid-mode-tooltip"
          data-tooltip-content="Single View"
        />
      ) : (
        <GridModeOn
          className="cursor-pointer"
          style={{ width: '64px', height: '64px', opacity: 0.5 }}
          onClick={toggleGridView}
          data-tooltip-id="grid-mode-tooltip"
          data-tooltip-content="Grid Mode"
        />
      )}
      <Tooltip id="grid-mode-tooltip" />
    </div>
  );
};

export default GridMode;