// src/index.tsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import Providers from './providers/Providers';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Polyfill Buffer for browser environment
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const container = document.getElementById('root');
const root = createRoot(container!); // Create a root.

root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);
