import React, { useState, useEffect } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { useGridView } from "../../context/GridViewContext";
import { useTheme } from '../../context/ThemeContext'; // Import useTheme
import { useUser } from '../../context/UserContext'; // Import useUser
import { NFTListMoshicamProps, NFT } from "../../types/types";
import greenCheck from "../../icons/greenCheck.svg";
import placeholderMoshi from "../../icons/moshicam-placeholder.png";
import WalletConnect from "../WalletConnect";
import MoshicamLogo from "../../icons/moshicam-logo.png";
import GradientButton from "../ui/GradientButton";

const NFTListMoshicam: React.FC<NFTListMoshicamProps> = ({
  nfts,
  selectedNFT,
  toggleSelectNFT,
  handleImageError,
  selectedCell,
  isMobile,
  searchQuery,
  sortMethod,
  toggleSortMethod,
  isRefetching,
  refreshTrigger,
}) => {
  const { isGridViewEnabled } = useGridView();
  const [infoNFT, setInfoNFT] = useState<NFT | null>(null);
  const { ready, authenticated } = usePrivy();
  const { secondaryColor } = useTheme(); // Use useTheme
  const { connectedWalletAddress, logout } = useUser(); // Use useUser

  const isSelected = (nft: NFT) =>
    selectedNFT !== null &&
    selectedNFT.contract === nft.contract &&
    selectedNFT.tokenId === nft.tokenId;

  const isSelectable = selectedCell !== null;

  const handleClickNFT = (nft: NFT) => {
    if (isSelectable) {
      toggleSelectNFT(nft);
    }
  };

  useEffect(() => {
    // This effect will run whenever refreshTrigger changes
    // You can add any additional logic here if needed
  }, [refreshTrigger]);

  if (!ready) {
    return <div className="flex flex-col items-center justify-center min-h-screen"><p>Loading...</p></div>;
  }

  if (!authenticated) {
    return (
      <div className="flex flex-col items-center justify-center mt-[130px] sm:mt-4 min-h-screen">
        <WalletConnect textColor="white" />
      </div>
    );
  }

  return (
    <div className="p-0 sm:p-2 h-full relative overflow-y-auto scrollbar-hide">
      {isRefetching && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
          <p className="text-white">Refreshing Photo Collection...</p>
        </div>
      )}
      {nfts.length > 0 ? (
        <>
          <div className={`grid ${isMobile ? 'grid-cols-3' : 'grid-cols-3'} gap-x-[1px] gap-y-[1px] max-w-[100%] sm:max-w-[95%] mx-auto p-0 sm:p-2 px-0.1 sm:px-2 shadow-inner relative`}>
            {/* Only show placeholders on mobile view */}
            {isMobile && Array.from({ length: 9 }).map((_, index) => (
              <div key={`placeholder-top-${index}`} className="relative nft-item bg-opacity-10 block sm:hidden">
                <img
                  src={placeholderMoshi}
                  className="w-25 h-30 object-cover m-auto"
                  alt="Placeholder"
                />
              </div>
            ))}
            {nfts.map((nft) => {
              const uniqueKey = `${nft.contract}-${nft.tokenId}`;
              return (
                <div
                  key={uniqueKey}
                  className={`relative nft-item ${
                    isSelected(nft) ? "selected" : ""
                  } ${
                    isSelectable ? "cursor-pointer" : "cursor-not-allowed"
                  } bg-opacity-10`}
                  onClick={() => handleClickNFT(nft)}
                  title={isSelectable ? "" : "Select a spot on the grid first..."}
                >
                  <img
                    src={nft.imageUrl}
                    className={`w-25 sm:w-28 h-30 sm:h-32 object-cover m-auto ${
                      isSelected(nft) ? "opacity-50" : ""
                    }`}
                    alt={nft.description || "Unnamed NFT"}
                    onError={(e) => handleImageError(e, nft)}
                  />
                  {isSelected(nft) && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div
                        className={`checkmark-container relative ${
                          isGridViewEnabled ? "left-[5px]" : "bottom-[240px]"
                        }`}
                      >
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={greenCheck}
                          alt="checkmark"
                        />
                      </div>
                    </div>
                  )}
                  {isGridViewEnabled && (
                    <div className="flex justify-center w-full">
                      <p className={`text-[0.5rem] ${isMobile ? 'sm:text-xs' : 'sm:text-sm'} text-white text-center mt-1`}>
                        {nft.description
                          ? nft.description.split("\n").map((line, index) => (
                              <span key={index}>
                                {line}
                                <br />
                              </span>
                            ))
                          : nft.name}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
            {/* Add 6 placeholders at the bottom, only on mobile view */}
            {isMobile && (
              <div className="block sm:hidden">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div key={`placeholder-bottom-${index}`} className="relative nft-item bg-opacity-10">
                    <img
                      src={placeholderMoshi}
                      className="w-25 h-30 object-cover m-auto"
                      alt="Placeholder"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-full mt-[130px] sm:mt-4">
          <div className="flex flex-col items-center">
            <p className="text-white text-sm sm:text-lg">
              Create or collect photos via
            </p>
            <a href="https://moshi.cam" target="_blank" rel="noopener noreferrer" className="flex items-center mt-4">
              <img src={MoshicamLogo} alt="Moshicam Logo" className="w-6 h-6 mx-2" />
              <span className="uppercase font-['Jersey_15'] text-2xl leading-none">Moshicam</span>
            </a>
            <p className="text-white text-sm sm:text-lg mt-4 text-center">
              Or connect another wallet containing Moshicam photos
            </p>
            <GradientButton
              onClick={logout}
              className={`block w-[80%] sm:w-[60%] text-center px-4 py-2 text-sm text-gray-700 hover:bg-opacity-80 flex items-center justify-center h-10 font-['Jersey_15'] text-xl mt-4`}
              style={{
                '--hover-bg-color': secondaryColor,
              }}
            >
              <span className="flex justify-center">Switch Wallets</span>
            </GradientButton>
          </div>
        </div>
      )}
      {infoNFT && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className={`bg-white p-2 ${isMobile ? 'sm:p-3' : 'sm:p-4'} rounded-lg`}>
            <h2 className={`text-lg ${isMobile ? 'sm:text-xl' : 'sm:text-2xl'} font-bold`}>
              {infoNFT.description}
            </h2>
            <p>Collection: {infoNFT.contract}</p>
            <p>Chain: {infoNFT.chainId}</p>
            <p>Original Creator: {infoNFT.originalCreator}</p>
            <p>Media Type: {infoNFT.mediaType}</p>
            <button
              className={`mt-2 ${isMobile ? 'sm:mt-3' : 'sm:mt-4'} bg-red-500 text-white px-2 sm:px-4 py-1 sm:py-2 rounded`}
              onClick={() => setInfoNFT(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NFTListMoshicam;