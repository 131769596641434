import React from "react";
import { GradientButtonProps } from "../../types/types";

const GradientButton: React.FC<GradientButtonProps> = ({
  onClick,
  onChange,
  className = "",
  children,
  as = "button",
  value,
  disabled = false,
  href,
  target,
  rel,
  ...props
}) => {
  const baseClassName = `panel bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 ${
    disabled ? "opacity-50 cursor-not-allowed" : ""
  } ${className}`;

  if (as === "select") {
    return (
      <div className="relative inline-block">
        <select
          value={value}
          onChange={onChange}
          className={`${baseClassName} appearance-none cursor-pointer pr-10`}
          disabled={disabled}
          {...props}
        >
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    );
  }

  if (as === "a") {
    return (
      <a
        href={href}
        target={target}
        rel={rel}
        className={baseClassName}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={baseClassName}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default GradientButton;
