const isLoggingEnabled = process.env.REACT_APP_ENABLE_LOGS === 'true';

export const log = (...args: any[]) => {
  if (isLoggingEnabled) {
    console.log(...args);
  }
};

export const showError = (...args: any[]) => {
  if (isLoggingEnabled) {
    console.error(...args);
  }
};

// Add other console methods as needed (warn, info, etc.)