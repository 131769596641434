// /src/context/UserContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useEnsLookup } from "../hooks/useEnsLookup";
import { UserContextType } from "../types/types"; // Import interfaces from types.ts

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { ready, authenticated, logout } = usePrivy();
  const { wallets } = useWallets();
  const [connectedWalletAddress, setConnectedWalletAddress] =
    useState<string>("");

  const { ensName: connectedEnsName, farcasterUsernames, lensUsernames } = useEnsLookup(wallets[0]?.address || ""); // Destructure usernames

  useEffect(() => {
    if (authenticated && wallets.length > 0) {
      const walletAddress = wallets[0].address;
      setConnectedWalletAddress(walletAddress);
    }
  }, [authenticated, wallets]);

  const user: UserContextType = {
    connectedEnsName,
    connectedWalletAddress,
    farcasterUsernames, // Add Farcaster usernames to user object
    lensUsernames, // Add Lens usernames to user object
    logout,
  };

  return (
    <UserContext.Provider value={user}>
      {ready ? children : <div>Loading...</div>}
    </UserContext.Provider>
  );
};
