import React, { createContext, useContext, useState, ReactNode } from "react";
import { GridViewOffContextProps } from "../types/types"; // Import interfaces from types.ts

const GridViewContext = createContext<
  GridViewOffContextProps | undefined
>(undefined);

export const useGridView = (): GridViewOffContextProps => {
  const context = useContext(GridViewContext);
  if (!context) {
    throw new Error(
      "useGridView must be used within a GridViewProvider"
    );
  }
  return context;
};

export const GridViewProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isGridViewEnabled, setisGridViewEnabled] = useState(true); // Change default to false

  const toggleGridView = () => {
    setisGridViewEnabled((prev) => !prev);
  };

  return (
    <GridViewContext.Provider
      value={{ isGridViewEnabled, toggleGridView }}
    >
      {children}
    </GridViewContext.Provider>
  );
};
