// /src/moshicam/SearchBarMobile.tsx

import React from "react";
import clearButton from "../../icons/clearButton.svg";

const SearchBarMobile: React.FC<{
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  nftsLength: number;
  className?: string; // Added className prop
}> = ({ searchQuery, setSearchQuery, nftsLength, className }) => { // Added className to props
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchQuery(searchValue);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <div className={`w-full flex flex-col items-start mb-2 px-2 relative ${className}`}> {/* Apply className to the root element */}
      <div className="absolute left-1/2 transform -translate-x-1/2 top-[0px] w-[60%] max-w-md">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={`Search ${nftsLength} items`}
          className="text-center bg-gray-800 p-[calc(2px-1.5px)] font-['Jersey_15'] uppercase text-2xl rounded-md w-full" // Adjusted padding to make it 5px shorter
        />
        {searchQuery.length > 0 && (
          <img
            src={clearButton}
            alt="Clear"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
            onClick={handleClearSearch}
          />
        )}
      </div>
    </div>
  );
};

export default SearchBarMobile;