// CurrentGrid.tsx
import React, { useState, useEffect, useRef } from "react";
import { useGridView } from "../../context/GridViewContext";
import { useMobile } from "../../context/MobileContext";
import selectedCellImage from "../../icons/selected.svg"

import { AttributeValue } from "../../types/types";
import ImageModal from "../../components/ui/ImageModal";
import moshicamLogo from "../../icons/moshicam-logo.png";
import { useEnsLookup } from "../../hooks/useEnsLookup";
import { log, showError } from '../../utils/logger';

import farcasterLogo from '../../icons/farcaster-transparent-purple.png';
import lensLogo from '../../icons/lens-logo.svg';
import ensLogo from '../../icons/ens-logo.svg';

// Instead of console.log
// log('This is a log message');
// Instead of console.error
// error('This is an error message');


interface CurrentGridProps {
  images: (AttributeValue | null)[];
  gridName: string;
  onCellSelect: (index: number) => void;
  clearCell: (index: number) => void;
  selectedCell: number | null;
  userCell: number | null;
  placedByAddresses: { [key: number]: string };
  tooltipText: string | null;
}

const CurrentGrid: React.FC<CurrentGridProps> = React.memo(({
  images,
  gridName,
  onCellSelect,
  clearCell,
  selectedCell,
  userCell,
  placedByAddresses,
  tooltipText
}) => {
  const { isMobile } = useMobile();
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const [displayText, setDisplayText] = useState<string | JSX.Element>(gridName);
  const { isGridViewEnabled } = useGridView();
  const [isScrolling, setIsScrolling] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const [textDisplayMode, setTextDisplayMode] = useState<
    "description" | "moshicamlink" | "placedby"
  >("description");
  const [currentPlacedBy, setCurrentPlacedBy] = useState<string | undefined>(undefined);

  const { ensName, resolvedAddress, farcasterUsernames, lensUsernames } = useEnsLookup(currentPlacedBy);

  useEffect(() => {
    if (modalIndex !== null) {
      const newPlacedBy = placedByAddresses[modalIndex];
      log(`Updating currentPlacedBy for index ${modalIndex}:`, newPlacedBy);
      setCurrentPlacedBy(newPlacedBy);
    } else {
      setCurrentPlacedBy(undefined);
    }
  }, [modalIndex, placedByAddresses]);

  useEffect(() => {
    log('Current placedByAddresses:', placedByAddresses);
    log('Current modalIndex:', modalIndex);
    log('Current currentPlacedBy:', currentPlacedBy);
    log('Current ensName:', ensName);
    log('Current resolvedAddress:', resolvedAddress);

    const scrollThreshold = 22;

    if (tooltipText && isMobile) {
      setDisplayText(tooltipText);
      setIsScrolling(tooltipText.length > scrollThreshold);
    } else if (modalIndex !== null && images[modalIndex]) {
      const description = images[modalIndex]?.description || "Moshi";
      switch (textDisplayMode) {
        case "description":
          setDisplayText(description);
          setIsScrolling(description.length > scrollThreshold);
          break;
        case "moshicamlink":
          setDisplayText("Open with: ");
          setIsScrolling(false);
          break;
        case "placedby":
          const profiles = [
            ensName ? (
              <span key="ens" className="inline-flex items-center">
                <img src={ensLogo} alt="ENS" className="w-4 h-4 mr-1" />
                {ensName}
              </span>
            ) : null,
            ...farcasterUsernames.map(name => (
              <a
                key={`fc:${name}`}
                href={`https://warpcast.com/${name}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center"
              >
                <img src={farcasterLogo} alt="Farcaster" className="w-4 h-4 mr-1" />
                {name}
              </a>
            )),
            ...lensUsernames.map(name => (
              <a
                key={`lens:${name}`}
                href={`https://hey.xyz/u/${name}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center"
              >
                <img src={lensLogo} alt="Lens" className="w-4 h-4 mr-1" />
                lens:{name}
              </a>
            ))
          ].filter((profile): profile is JSX.Element => profile !== null);
          
          const placedByText = profiles.length > 0
            ? <span className="inline-flex items-center">
                <span className="mr-2">Added By:</span>
                {profiles.reduce((prev, curr, index) => (
                  <React.Fragment key={index}>
                    {prev}
                    {index > 0 && <span className="mx-1">/</span>}
                    {curr}
                  </React.Fragment>
                ))}
              </span>
            : `Added By: ${resolvedAddress || currentPlacedBy || "Unknown"}`;

          log('Setting placedByText:', placedByText);
          setDisplayText(placedByText);

          // Calculate the total length of the placedByText
          const placedByTextLength = profiles.reduce((acc, profile) => {
            const textContent = profile.props.children.reduce((textAcc: string, child: any) => 
              textAcc + (typeof child === 'string' ? child : child.props.children), '');
            return acc + textContent.length;
          }, "Added By: ".length);

          setIsScrolling(placedByTextLength >= 20);
          break;
      }
    } else {
      setDisplayText(gridName);
      setIsScrolling(gridName.length > scrollThreshold);
    }
  }, [
    modalIndex,
    images,
    gridName,
    textDisplayMode,
    currentPlacedBy,
    ensName,
    resolvedAddress,
    farcasterUsernames,
    lensUsernames,
    tooltipText,
    placedByAddresses,
    isMobile,
  ]);

  const handleCellClick = (index: number) => {
    if (images[index] !== null) {
      setModalIndex(index);
      if (isMobile) {
        // showTooltip(images[index]?.description || "Moshi");
      }
    } else {
      onCellSelect(index);
    }
  };

  const closeModal = () => {
    setModalIndex(null);
    setDisplayText(gridName);
    setTextDisplayMode("description");
  };

  const navigateNext = () => {
    if (modalIndex !== null) {
      let nextIndex = (modalIndex + 1) % images.length;
      while (images[nextIndex] === null) {
        nextIndex = (nextIndex + 1) % images.length;
      }
      setModalIndex(nextIndex);
    }
  };

  const navigatePrevious = () => {
    if (modalIndex !== null) {
      let prevIndex = (modalIndex - 1 + images.length) % images.length;
      while (images[prevIndex] === null) {
        prevIndex = (prevIndex - 1 + images.length) % images.length;
      }
      setModalIndex(prevIndex);
    }
  };

  const generateMoshicamLink = (contractAddress: string, tokenId: string) => {
    return `https://moshi.cam/pics/${contractAddress}/${tokenId}`;
  };

  useEffect(() => {
    if (isScrolling && textRef.current) {
      const textWidth = textRef.current.scrollWidth;
      const containerWidth = textRef.current.offsetWidth;
      const animationDuration = textWidth / 50 + "s";
      textRef.current.style.animation = `scroll-left ${animationDuration} linear infinite`;
    } else if (textRef.current) {
      textRef.current.style.animation = "none";
    }
  }, [isScrolling, displayText]);

  const handleTextClick = () => {
    if (modalIndex !== null) {
      setTextDisplayMode((prevMode) => {
        switch (prevMode) {
          case "description":
            return "moshicamlink";
          case "moshicamlink":
            return "placedby";
          case "placedby":
            return "description";
        }
      });
    }
  };

  useEffect(() => {
    const allCellsBlank = images.every(image => image === null);
    if (allCellsBlank && modalIndex !== null) {
      closeModal();
    }
  }, [images]);

  // Create dynamic classes based on isMobile state
  const containerClasses = isMobile
    ? "w-[250px] h-[300px]"
    : "w-[350px] h-[420px]";

  const innerContainerClasses = isMobile
    ? "p-0"
    : "p-3";

  return (
    <div className="flex flex-col items-center w-full">
      <div className={`${containerClasses} bg-white bg-opacity-5 backdrop-blur-md shadow-lg shadow-black/30 flex items-center justify-center overflow-hidden relative z-10 border border-white/10`}>
        <div className={`flex justify-center w-full h-full ${innerContainerClasses} shadow-[0_15px_35px_rgba(0,0,0,0.5)]`}>
          <div className={`grid grid-cols-3 gap-x-[5px] gap-y-[5px] h-full w-full p-0 sm:p-2 shadow-none sm:shadow-inner bg-black bg-opacity-0 sm:bg-opacity-20 sm:grid-cols-3 sm:gap-x-[3px] sm:gap-y-[3px] sm:p-2 ${modalIndex !== null ? 'hidden' : ''}`}>
            {images.map((item, index) => (
              <div
                key={index}
                className={`relative group cursor-pointer overflow-hidden w-full h-full ${
                  item
                    ? "bg-green-500 bg-opacity-20"
                    : "bg-white bg-opacity-10 hover:bg-opacity-50"
                } transition-all duration-300 ease-in-out sm:w-full sm:h-full sm:bg-opacity-20 sm:hover:bg-opacity-50`}
                onClick={() => handleCellClick(index)}
                style={{
                  aspectRatio: "1 / 1",
                }}
              >
                {item ? (
                  <>
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-full h-full object-cover transition-transform duration-300 ease-in-out scale-[1.02] sm:scale-[1.02]"
                      style={{ position: "absolute", top: 0, left: 0 }}
                    />
                    {userCell === index && (
                      <div className="absolute top-0 right-0 z-10">
                        <button
                          className="w-6 h-6 flex items-center justify-center bg-red-500 text-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            clearCell(index);
                          }}
                        >
                          X
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    {selectedCell === index && (
                      <div
                        className="absolute top-1.75 left-1.0 flex items-center justify-center"
                        style={{
                          width: "95%",
                          height: "90%",
                        }}
                      >
                        <img
                          src={selectedCellImage}
                          alt="Selected"
                          className="w-auto h-full object-contain"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {modalIndex !== null && images[modalIndex] !== null && (
          <div className="absolute inset-0 z-20 flex items-center justify-center">
            <div className="w-full h-full max-w-[80%] max-h-[80%]">
              <ImageModal
                images={images.map((image) =>
                  image
                    ? {
                        contract: image.contract,
                        image: image.image,
                        name: image.name,
                        tokenId: image.tokenId,
                      }
                    : null
                )}
                currentIndex={modalIndex}
                onClose={closeModal}
                onNavigateNext={navigateNext}
                onNavigatePrevious={navigatePrevious}
              />
            </div>
          </div>
        )}
      </div>
      {isGridViewEnabled && (
        <div className="w-[78%] sm:w-[100%] pt-2 bg-white bg-opacity-10 backdrop-blur-md shadow-lg shadow-black/50 mt-0 text-center text-white transition-all duration-300 ease-in-out overflow-hidden">
          <div
            ref={textRef}
            className="text-lg sm:text-xl font-bold mb-1 sm:mb-2 whitespace-nowrap font-tiny5 inline-block cursor-pointer flex items-center justify-center px-0 sm:px-0"
            onClick={handleTextClick}
          >
            {React.isValidElement(displayText) ? displayText : <span>{displayText}</span>}
            {textDisplayMode === "moshicamlink" &&
              modalIndex !== null &&
              images[modalIndex] && (
                <a
                  href={generateMoshicamLink(
                    images[modalIndex]?.contract || "",
                    images[modalIndex]?.tokenId || ""
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={moshicamLogo}
                    alt="Moshicam Logo"
                    className="h-4 sm:h-6 ml-1 sm:ml-2 inline-block"
                  />
                </a>
              )}
          </div>
        </div>
      )}
    </div>
  );
});

export default CurrentGrid;