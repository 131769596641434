import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SingleGridMoshicam from '../../components/moshicam/SingleGridMoshicam';
import { log, showError } from '../../utils/logger';

interface GridData {
  _id: string;
  description: string;
  gridColumns: number;
  name: string;
  items: {
    [key: string]: {
      contract: string;
      description: string;
      tokenId: number | string;
      name: string;
      originalCreator: string;
      image: string;
      chainId: number;
      placedby: string;
    }
  };
  imageUrl: string | null;
  imageUrlOriginal: string | null;
  tokenURI: string | null;
}

interface FeedSingleGridProps {
  dayNumber: string;
  gridNumber: string;
}

const FeedSingleGrid: React.FC<FeedSingleGridProps> = ({ dayNumber, gridNumber }) => {
  const [grid, setGrid] = useState<GridData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGrid = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/moshicam/fetchGrid?dayNumber=${dayNumber}&gridNumber=${gridNumber}`);
        if (response.data && response.data._id) {
          setGrid(response.data);
        } else {
          navigate(`/moshicam/day/${dayNumber}`);
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch grid');
        setLoading(false);
        navigate(`/moshicam/day/${dayNumber}`);
      }
    };

    fetchGrid();
  }, [dayNumber, gridNumber, navigate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="feed-single-grid mt-[45px] sm:mt-0">
      {grid && (
        <div className="grid-container mb-[10]">
          <SingleGridMoshicam gridData={grid} />
        </div>
      )}
    </div>
  );
};

export default FeedSingleGrid;