import React from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";
import GridModeOn from '../../icons/GridModeOn.svg';
import GridModeOff from '../../icons/GridModeOff.svg';
import { useTheme } from '../../context/ThemeContext';

interface SidebarHeaderMobileProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  isGridViewEnabled: boolean;
  toggleGridView: () => void;
  onClose: () => void;
}

const SidebarHeaderMobile: React.FC<SidebarHeaderMobileProps> = ({
  isMenuOpen,
  toggleMenu,
  isGridViewEnabled,
  toggleGridView,
  onClose,
}) => {
  const { primaryColor, secondaryColor, textColor } = useTheme();

  const linkStyle = {
    color: textColor,
    transition: 'color 0.3s ease',
  };

  return (
    <div className="text-white flex items-center justify-between w-full h-[60px] p-4 fixed top-0 left-0 right-0 z-50" style={{ backgroundColor: primaryColor }}>
      <Link
        to="/"
        className="font-bold text-2xl block uppercase font-tiny5 mx-auto"
        style={linkStyle}
        onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
        onMouseLeave={(e) => e.currentTarget.style.color = textColor}
        onClick={onClose}
      >
        The Grid
      </Link>
      <div className="flex items-center absolute right-4">
        <button
          className="mr-4 opacity-40 hover:opacity-80"
          onClick={toggleGridView}
        >
          <img src={isGridViewEnabled ? GridModeOff : GridModeOn} alt="Grid Mode" className="h-8" />
        </button>
        <button 
          onClick={toggleMenu}
          style={linkStyle}
          onMouseEnter={(e) => e.currentTarget.style.color = secondaryColor}
          onMouseLeave={(e) => e.currentTarget.style.color = textColor}
        >
          {isMenuOpen ? <FaTimes /> : <FaBars style={{ height: '50px' }} />}
        </button>
      </div>
    </div>
  );
};

export default SidebarHeaderMobile;