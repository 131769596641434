import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useParams, Navigate } from "react-router-dom";
import Contribute from "../pages/moshicam/Contribute";
import FeedAllDays from "../pages/moshicam/feedAllDays";
import Feedback from "../pages/moshicam/Feedback";
import WelcomePage from "../pages/Welcome";
import Sidebar from '../components/ui/Sidebar';
import { useMobile } from "../context/MobileContext";
import { log, showError } from '../utils/logger';
import Help from '../pages/Help'; // Import the Instructions component
import SubmitTheme from '../pages/moshicam/SubmitTheme';
import FeedMoshicam from '../pages/moshicam/feedSingleDay'; // Import the FeedMoshicam component
import FeedSingleGrid from '../pages/moshicam/feedSingleGrid'; // Import the new FeedSingleGrid component

const FeedMoshicamWrapper: React.FC = () => {
  const { dayNumber } = useParams<{ dayNumber: string }>();
  return <FeedMoshicam dayNumber={dayNumber!} />;
};

const FeedSingleGridWrapper: React.FC = () => {
  const { dayNumber, gridNumber } = useParams<{ dayNumber: string, gridNumber: string }>();
  return <FeedSingleGrid dayNumber={dayNumber!} gridNumber={gridNumber!} />;
};

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const isWelcomePage = location.pathname === "/";
  const { isMobile } = useMobile();

  useEffect(() => {
    log("Current path:", location.pathname);
    log("Is welcome page:", isWelcomePage);
  }, [location]);

  return (
    <div className={`App flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
      {!isWelcomePage && (
        <Sidebar isMobile={isMobile} isOpen={true} onClose={() => {}} />
      )}
      <div className={`flex-grow p-4 text-white main-content ${!isWelcomePage && !isMobile ? 'with-sidebar' : ''}`} style={{ minHeight: isMobile ? '40px' : '100vh' }}>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/moshicam" element={<Contribute />} />
          <Route path="/moshicam/today" element={<Contribute />} />
          <Route path="/moshicam/feed" element={<FeedAllDays />} />
          <Route path="/moshicam/feedback" element={<Feedback />} />
          <Route path="/help" element={<Help />} /> {/* Add the new route */}
          <Route path="/moshicam/submit-theme" element={<SubmitTheme />} />
          <Route path="/moshicam/day/:dayNumber" element={<FeedMoshicamWrapper />} /> {/* New route */}
          <Route path="/moshicam/day/:dayNumber/:gridNumber" element={<FeedSingleGridWrapper />} /> {/* New route */}
          <Route path="/moshicam/FarcasterFridays" element={<Navigate to="/moshicam/day/32" replace />} /> {/* New redirect route */}
        </Routes>
      </div>
    </div>
  );
};

export default AppRoutes;