import React from "react";
import { NFTDetailsProps } from "../../types/types"; // Import interfaces from types.ts

const NFTDetails: React.FC<NFTDetailsProps> = ({ nft, onClose }) => {
  if (!nft) return null;

  return (
    <div className="nft-details-modal">
      <button onClick={onClose}>Close</button>
      <img src={nft.image || "default-image-url"} alt={nft.name} />{" "}
      {/* Use a default URL */}
      <h2>{nft.name}</h2>
      <p>Contract: {nft.contract}</p>
      <p>Token ID: {nft.tokenId}</p>
      <p>Original Creator: {nft.originalCreator}</p>
      <p>Chain ID: {nft.chainId}</p>
    </div>
  );
};

export default NFTDetails;
